import strings from "../constants/locale";
import { Schedule } from "./Schedule";

export enum CategoryPromotionType {
  Discount = 0,
  HighestPrice = 1,
  FreeDelivery = 2,
  MinimumProduct = 3,
}

export type CategoryPromotion = {
  enabled: boolean;
  type: CategoryPromotionType;
  parameter: any;
  showBanner?: boolean;
  bannerImage?: string;
};

export type MenuCategory = {
  id: string;
  name: string;
  parentId?: string;
  image?: string;
  order?: number;
  promotion?: CategoryPromotion;
  scheduleEnabled?: boolean;
  schedule?: Schedule;
  createdAt: Date;
  updatedAt: Date;
};

export type MenuModifierItem = {
  id: string;
  name: string;
  price?: number;
};

export type MenuModifier = {
  id: string;
  name: string;
  minimumSelected?: number;
  maximumSelected?: number;
  isRequired: boolean;
  items: MenuModifierItem[];
  createdAt: Date;
  updatedAt: Date;
  excluded?: boolean;
};

export type CategoriesOrder = {
  [categoryId: string]: number;
};

export enum MenuItemStock {
  IN_STOCK = "in_stock",
  OUT_OF_STOCK = "out_of_stock",
  DISCONTINUED = "discontinued",
}

export type MenuItem = {
  id: string;
  name: string;
  description: string;
  internalCode: string;
  image: string;
  price: number;
  quantity: number;
  stock: MenuItemStock;
  categories: string[];
  categoriesOrder?: CategoriesOrder;
  modifiers?: string[];
  createdAt: Date;
  updatedAt: Date;
};

export type MenuExclusion = {
  id: string;
  menuCategoryId?: string;
  menuItemId?: string;
  menuModifierId?: string;
  menuModifierItemsExcluded?: string[];
  reason?: string;
  createdAt: Date;
  updatedAt: Date;
};

export const categoryPromotionTypeMap = {
  [CategoryPromotionType.Discount]: strings.discount,
  [CategoryPromotionType.HighestPrice]: strings.highestPrice,
  [CategoryPromotionType.FreeDelivery]: strings.freeDelivery,
  [CategoryPromotionType.MinimumProduct]: strings.minimumProduct,
};
