/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Field,
  Modal,
  AntSelect,
  DatePicker,
  Select,
  MultiSelect,
} from "../../../components";
import locale from "../../../constants/locale";
import {
  BoardColummType,
  BoardItemCard,
  BoardItemHistory,
  channelMap,
  Chat,
  TicketPriority,
  ticketPriorityMap,
  ticketRepeatDailyOptionMap,
  TicketRepeatFrequency,
  ticketRepeatMap,
  ticketRepeatMonthlyOptionMap,
  ticketRepeatYearlyOptionMap,
  TicketStatus,
  ticketStatusMap,
  TicketType,
  ticketTypeMap,
  UserType,
} from "../../../models";
import { useStore } from "../../../stores";
import { Button, Divider, Spin } from "antd";
import { debounce } from "lodash";
import { getContacts, notifyUserForNewTask } from "../../../services/chathub";
import { FaPlusSquare } from "react-icons/fa";
import AddContact from "../../contacts/components/AddContact";
import { useHistory, useLocation, useParams } from "react-router";
import ColumnModal from "./ColumnModal";
import { onlyDecimalNumbers } from "../../../utils/numbers";
import { toast } from "bulma-toast";
import collect from "collect.js";
import clsx from "clsx";
import Comments from "./comments/Comments";
import CustomFields from "./CustomFields";
import ChatQuickView from "../../chats/components/ChatQuickView";
import Labels from "../../chats/Tickets/components/Labels";
import UploadAttachment from "../../chats/Tickets/components/UploadAttachment";
import MetaData from "../../chats/Tickets/components/MetaData";
import TempComments from "./comments/TempComments";

const ItemModal = ({
  defaultOpen,
  disabled,
  onClose,
  item,
  columnId,
  isHelpCenter,
  allowLivecardToFetchBoard,
  disabledLivecard,
}: {
  disabled?: boolean;
  defaultOpen?: boolean;
  onClose?: (updated?: boolean) => void;
  item?: BoardItemCard;
  columnId?: number;
  isHelpCenter?: boolean;
  allowLivecardToFetchBoard?: boolean;
  disabledLivecard?: boolean;
}) => {
  const { t } = useTranslation();
  const { user, getPermissions } = useStore("auth");
  const { selectedBot, activeLiveChatCards } = useStore("bots");
  const { group } = useParams<{
    group: string;
  }>();
  const [loadingStages, setLoadingStages] = useState(false);
  const {
    boardMode,
    selectedBoard,
    addItemToBoard,
    updateItemToBoard,
    deleteItemFromBoard,
    archivedItem,
    setSelectedBoard,
    findBoardByLivecardId,
  } = useStore("boards");
  const { users } = useStore("users");
  const [open, setOpen] = useState(defaultOpen ?? false);
  const [deletingItem, setDeletingItem] = useState(false);
  const [archivingItem, setArchivingItem] = useState(false);
  const [tab, setTab] = useState("comments");
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const [stageId, setStageId] = useState(columnId);
  const paramCommentId = queryParams.get("commentId");

  const boardColumns = useMemo(
    () =>
      collect(selectedBoard?.columns)
        .mapWithKeys((f: any) => [f.id, f.name])
        .all() as any,
    [selectedBoard]
  );

  const hasClosedLost = useMemo(
    () =>
      selectedBoard?.columns?.find((f) => f.id === stageId)?.type ===
      BoardColummType.ClosedLost,
    [selectedBoard, stageId]
  );

  const deleteItem = async () => {
    if (!item) return;
    setDeletingItem(true);
    try {
      await deleteItemFromBoard(item, columnId as never);
      onClose && onClose();
    } catch (error) {
      setDeletingItem(false);
      if (error instanceof Error) {
        toast({
          message: t(locale.genericError),
          position: "top-right",
          dismissible: true,
          type: "is-danger",
          pauseOnHover: true,
        });
      }
    }
  };

  const onArchived = async () => {
    if (!item) return;
    setArchivingItem(true);
    try {
      await archivedItem(item);
      onClose && onClose();
    } catch (error) {
      setArchivingItem(false);
      if (error instanceof Error) {
        toast({
          message: t(locale.genericError),
          position: "top-right",
          dismissible: true,
          type: "is-danger",
          pauseOnHover: true,
        });
      }
    }
  };

  const isCollaborator = item && user?.type === UserType.Collaborator;

  const collaboratorCanDelete = useMemo(
    () => getPermissions()?.canCreateOrDeleteDeals,
    [getPermissions]
  );

  useEffect(() => {
    if (open && paramCommentId && item?.comments?.length) {
      const comment = item.comments.find(
        (f) => f.id === parseInt(paramCommentId)
      );
      if (comment) {
        setTimeout(() => {
          document
            .getElementById("ticket-comment-" + comment.id)
            ?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          const { pathname } = location;
          history.push(pathname);
        }, 200);
      }
    }
  }, [
    open,
    paramCommentId,
    item?.comments,
    item?.comments?.length,
    location,
    history,
  ]);

  const loadTeamBoard = useCallback(
    async (livecardId: number) => {
      setLoadingStages(true);
      try {
        await findBoardByLivecardId(String(livecardId));
      } catch (error) {
      } finally {
        setLoadingStages(false);
      }
    },
    [findBoardByLivecardId]
  );

  const correctColumnIdIfStatusIsCompleted = (
    columndId: number,
    status?: TicketStatus
  ) => {
    if (boardMode === "pipeline") {
      return columndId;
    } else if (status !== TicketStatus.DONE) {
      return columndId;
    }

    const column = selectedBoard?.columns?.find(
      (f) => f.type === BoardColummType.Completed
    );

    if (!column) {
      return columndId;
    }

    return column.id;
  };

  return (
    <>
      {!defaultOpen && (
        <button
          disabled={disabled}
          className="button is-rounded is-ghosted"
          onClick={() => setOpen(true)}
        >
          <div className="icon" style={{ position: "absolute" }}>
            <img src="/assets/add-blue.svg" alt="" />
          </div>
        </button>
      )}
      <Formik
        initialValues={{
          name: "",
          subscriberId: undefined,
          assigneeId: undefined,
          ...(boardMode === "ticket" && {
            livecardId: group ? parseInt(group) : undefined,
            type: TicketType.TASK,
            status: TicketStatus.TODO,
            priority: TicketPriority.MEDIUM,
          }),
          ...item,
          dueDate: item?.dueDate ? item.dueDate.toJSDate() : undefined,
          columnId,
          closedLostReason: item?.closedLostReason ?? "",
          customFields: item?.customFields?.map((f) => ({
            ...f,
            value: f.type === "date" ? new Date(f.value) : f.value,
          })),
        }}
        validationSchema={yup.object({
          name: yup.string().required(t(locale.fieldRequired)),
          columnId: yup.number().required(t(locale.fieldRequired)),
          assigneeId: yup.string().required(t(locale.fieldRequired)),
          ...(!isHelpCenter && {
            dueDate: yup.date().required(t(locale.fieldRequired)),
          }),
          ...(boardMode === "pipeline" && {
            opportunity: yup.number().required(t(locale.fieldRequired)),
            subscriberId: yup.string().required(t(locale.fieldRequired)),
          }),
          ...(boardMode === "ticket" && {
            type: yup.string().required(t(locale.fieldRequired)),
            status: yup.string().required(t(locale.fieldRequired)),
            priority: yup.string().required(t(locale.fieldRequired)),
            livecardId: yup.number().required(t(locale.fieldRequired)),
          }),
          ...(hasClosedLost && {
            closedLostReason: yup
              .string()
              .max(30)
              .required(t(locale.fieldRequired)),
          }),
          customFields: yup.array().of(
            yup.object({
              value: yup.string().nullable().required(t(locale.requiredField)),
            })
          ),
        })}
        onSubmit={async (data, { resetForm, setFieldValue }) => {
          try {
            data.columnId = correctColumnIdIfStatusIsCompleted(
              data.columnId as number,
              data?.status
            );
            if (item?.id) {
              if (selectedBoard && columnId !== data.columnId) {
                const sourceColumn = selectedBoard?.columns?.find(
                  (f) => f.id === columnId
                );
                const destinationColumn = selectedBoard?.columns?.find(
                  (f) => f.id === data.columnId
                );
                // If source is different from destination, we need to update multiple columns
                const sourceItems = sourceColumn?.items ?? [];
                const sourceItem = sourceItems.find((f) => f.id === item.id);
                if (!sourceItem) return;

                // Filter the start list like before
                const newStartList =
                  sourceColumn?.items?.filter((f) => f.id !== data.id) ?? [];

                // Make a new end list array
                const newEndList = destinationColumn?.items ?? [];

                // Insert the item into the end list
                newEndList.push(sourceItem);
                setSelectedBoard({
                  ...selectedBoard,
                  columns: selectedBoard?.columns?.map((col) => {
                    if (col.uuid === sourceColumn?.uuid) {
                      return {
                        ...col,
                        items: newStartList,
                      };
                    } else if (col.uuid === destinationColumn?.uuid) {
                      return {
                        ...col,
                        items: newEndList,
                      };
                    }
                    return col;
                  }),
                });
              }
              await updateItemToBoard(
                {
                  ...item,
                  ...data,
                  opportunity: parseFloat(
                    data.opportunity as unknown as string
                  ),
                  updatedAt: new Date(),
                } as any,
                data.columnId as never,
                columnId as never,
                item?.assigneeId
              );
            } else {
              await addItemToBoard(
                {
                  type: "card",
                  ...data,
                  opportunity: parseFloat(
                    data.opportunity as unknown as string
                  ),
                } as any,
                data.columnId as never
              );
            }
            //TODO move to backend
            if (
              selectedBot &&
              boardMode === "ticket" &&
              item?.assigneeId !== data.assigneeId
            ) {
              const user = users?.find((f) => f.id === data.assigneeId);
              if (user && user.email) {
                await notifyUserForNewTask(
                  selectedBot.id,
                  selectedBot.key,
                  selectedBot.password,
                  user.email,
                  group ?? data.livecardId
                );
              }
            }
            setOpen(false);
            resetForm();
            setFieldValue("opportunity", "");
            if (onClose) {
              onClose(true);
            }
          } catch (error: any) {
            console.log("error", error);
            let message = t(locale.genericError);
            if (error?.status === 400) {
              message = error.response.data.error;
            }
            toast({
              message,
              position: "top-right",
              dismissible: true,
              type: "is-danger",
              pauseOnHover: true,
            });
          }
        }}
      >
        {({ isSubmitting, submitForm, resetForm, values, setFieldValue }) => (
          <Modal
            isOpen={open}
            title={t(boardMode === "pipeline" ? locale.deal : locale.ticket)}
            onClose={() => {
              setOpen(false);
              resetForm();
              if (onClose) {
                onClose();
              }
            }}
            primaryText={t(item?.id ? locale.update : locale.save)}
            primaryLoading={isSubmitting}
            onPrimary={isSubmitting ? undefined : submitForm}
            isLargeScreen
          >
            <Form>
              <div className="columns is-multiline">
                <div className="column is-half">
                  <Field
                    name="name"
                    label={t(locale.name)}
                    placeholder={"Enter " + t(locale.name)}
                    className="custom-input"
                    disabled={isSubmitting || isCollaborator}
                  />
                </div>
                {boardMode === "ticket" && !isHelpCenter && (
                  <div className="column is-half">
                    <Select
                      name="livecardId"
                      label={"Group"}
                      disabled={isSubmitting || !!group || disabledLivecard}
                      options={activeLiveChatCards}
                      getLabel={(l) => l.name}
                      getValue={(l) => l.id}
                      className="custom-select"
                      onChange={(v) => {
                        if (allowLivecardToFetchBoard) {
                          setStageId(undefined);
                          setFieldValue("columnId", undefined);
                          loadTeamBoard(v as number);
                        }
                      }}
                    />
                  </div>
                )}
                <div className="column is-half">
                  <StageSelector
                    disabled={isSubmitting || loadingStages}
                    onChange={setStageId}
                  />
                </div>
                <div className="column is-half">
                  <AccountSelector
                    subcriber={item?.subscriber}
                    disabled={isSubmitting || isCollaborator || disabled}
                    name="subscriberId"
                  />
                </div>
                {!isHelpCenter && (
                  <div className="column is-half">
                    <DatePicker
                      name="dueDate"
                      label={t(locale.estimatedCloseDate)}
                      disabled={isSubmitting}
                      minDate={new Date()}
                      inputClass="custom-input"
                      wrapperClassName="w-100"
                      showTimeInput
                      dateFormat="MM/dd/yyyy h:mm aa"
                    />
                  </div>
                )}
                <div className="column is-half">
                  <AntSelect
                    showSearch
                    filterOption={(input: any, option: any) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    name="assigneeId"
                    label={t(locale.assignee)}
                    allowClear
                    options={
                      users?.map((f) => ({
                        value: f.id,
                        label: f.name || f.email,
                      })) || []
                    }
                    disabled={isSubmitting || isCollaborator}
                  />
                </div>
                {boardMode === "pipeline" && (
                  <div className="column is-half">
                    <Field
                      name="opportunity"
                      label={t(locale.opportunity)}
                      placeholder={"Enter " + t(locale.opportunity)}
                      className="custom-input"
                      disabled={isSubmitting}
                      inputProps={{
                        onKeyDown: onlyDecimalNumbers,
                      }}
                    />
                  </div>
                )}
                {boardMode === "ticket" && (
                  <>
                    <div className="column is-half">
                      <Select
                        name="priority"
                        label={t(locale.priority)}
                        disabled={isSubmitting}
                        options={Object.keys(ticketPriorityMap)}
                        getLabel={(l) =>
                          t(ticketPriorityMap[l as TicketPriority])
                        }
                        className="custom-select"
                        labelIsNotAOption
                      />
                    </div>
                    <div className="column is-half">
                      <Select
                        name="status"
                        label={t(locale.status)}
                        disabled={isSubmitting}
                        options={[
                          TicketStatus.TODO,
                          TicketStatus.IN_PROGRESS,
                          TicketStatus.DONE,
                        ]}
                        getLabel={(l) => t(ticketStatusMap[l])}
                        className="custom-select"
                        labelIsNotAOption
                      />
                    </div>
                    <div className="column is-half">
                      <Select
                        name="type"
                        label={t(locale.type)}
                        disabled={isSubmitting}
                        options={[TicketType.TASK, TicketType.TICKET]}
                        getLabel={(l) => t(ticketTypeMap[l])}
                        className="custom-select"
                        labelIsNotAOption
                      />
                    </div>
                    {!isHelpCenter && (
                      <div className="column is-half">
                        <Select
                          name="repeatFrequency"
                          label={t(locale.repeat)}
                          disabled={isSubmitting}
                          options={Object.keys(ticketRepeatMap)}
                          getLabel={(l) => t(ticketRepeatMap[l as never])}
                          onChange={() => setFieldValue("repeatConfig", [])}
                          className="custom-select"
                          labelIsNotAOption
                          extras={
                            values.repeatFrequency &&
                            values.repeatFrequency !==
                              TicketRepeatFrequency.NONE ? (
                              <span className="subtitle is-7 has-text-grey">
                                {"Cron job will be run on utc time"}
                              </span>
                            ) : (
                              <span></span>
                            )
                          }
                        />
                        {values.repeatFrequency ===
                          TicketRepeatFrequency.WEEKLY && (
                          <MultiSelect
                            name="repeatConfig"
                            label={t(locale.every)}
                            options={Object.keys(
                              ticketRepeatDailyOptionMap
                            ).map((f) => parseInt(f))}
                            getLabel={(v) =>
                              t(ticketRepeatDailyOptionMap[v as never])
                            }
                          />
                        )}
                        {values.repeatFrequency ===
                          TicketRepeatFrequency.MONTHLY && (
                          <MultiSelect
                            name="repeatConfig"
                            label={t(locale.every)}
                            options={Object.keys(
                              ticketRepeatMonthlyOptionMap
                            ).map((f) => parseInt(f))}
                            getLabel={(v) =>
                              t(ticketRepeatMonthlyOptionMap[v as never])
                            }
                          />
                        )}
                        {values.repeatFrequency ===
                          TicketRepeatFrequency.YEARLY && (
                          <MultiSelect
                            name="repeatConfig"
                            label={t(locale.every)}
                            options={Object.keys(
                              ticketRepeatYearlyOptionMap
                            ).map((f) => parseInt(f))}
                            getLabel={(v) =>
                              t(ticketRepeatYearlyOptionMap[v as never])
                            }
                          />
                        )}
                      </div>
                    )}
                  </>
                )}
                {hasClosedLost && (
                  <div className="column is-half">
                    <Field
                      name="closedLostReason"
                      label={t(locale.reason)}
                      placeholder={"Enter " + t(locale.reason)}
                      className="custom-input"
                      disabled={isSubmitting}
                      inputProps={{
                        maxLength: 30,
                      }}
                      showCounter
                    />
                  </div>
                )}
              </div>

              {item && (
                <div className="w-100 has-text-right">
                  <button
                    onClick={onArchived}
                    disabled={
                      archivingItem ||
                      isSubmitting ||
                      (isCollaborator && !collaboratorCanDelete)
                    }
                    className="button mr-2 p-0 is-no-box-shadow is-ghost has-text-primary"
                  >
                    {item?.archivedAt ? t(locale.remove) : ""}{" "}
                    {t(locale.archived)}
                  </button>
                  <button
                    onClick={deleteItem}
                    disabled={
                      deletingItem ||
                      isSubmitting ||
                      (isCollaborator && !collaboratorCanDelete)
                    }
                    className="button p-0 is-no-box-shadow is-ghost has-text-danger"
                  >
                    {t(locale.delete)}
                  </button>
                </div>
              )}
              <div className="column is-full" style={{ minHeight: "16rem" }}>
                <div className="tabs">
                  <ul>
                    <li className={clsx({ "is-active": tab === "comments" })}>
                      <a onClick={() => setTab("comments")}>
                        {t(locale.comments)}
                      </a>
                    </li>
                    <li
                      className={clsx({ "is-active": tab === "attachments" })}
                    >
                      <a onClick={() => setTab("attachments")}>
                        {t(locale.attachments)}
                      </a>
                    </li>
                    <li className={clsx({ "is-active": tab === "labels" })}>
                      <a onClick={() => setTab("labels")}>{t(locale.labels)}</a>
                    </li>
                    {!!item && (
                      <li className={clsx({ "is-active": tab === "history" })}>
                        <a onClick={() => setTab("history")}>
                          {t(locale.history)}
                        </a>
                      </li>
                    )}
                    <li
                      className={clsx({ "is-active": tab === "customFields" })}
                    >
                      <a onClick={() => setTab("customFields")}>
                        {t(locale.customFields)}
                      </a>
                    </li>
                    {boardMode === "ticket" && (
                      <li
                        className={clsx({ "is-active": tab === "meta-data" })}
                      >
                        <a onClick={() => setTab("meta-data")}>
                          {t(locale.metaData)}
                        </a>
                      </li>
                    )}
                  </ul>
                </div>

                <div className="columns m-0">
                  {tab === "comments" && (
                    <div className="column is-half">
                      {item?.id ? (
                        <Comments boardItem={item} />
                      ) : (
                        <TempComments
                          value={values?.comments ?? []}
                          onChange={(v) => setFieldValue("comments", v)}
                        />
                      )}
                    </div>
                  )}
                  {tab === "labels" && (
                    <div className="column is-half">
                      <Labels
                        value={values?.labels}
                        onChange={(v) => setFieldValue("labels", v)}
                      />
                    </div>
                  )}
                  {tab === "attachments" && (
                    <div className="column is-half">
                      <UploadAttachment
                        value={values?.attachments}
                        onChange={(v) => setFieldValue("attachments", v)}
                        setTempFiles={() => {}}
                      />
                    </div>
                  )}
                  {tab === "history" && (
                    <Histories
                      boardColumns={boardColumns}
                      histories={item?.histories}
                    />
                  )}
                  {tab === "customFields" && (
                    <div className="column is-half">
                      <CustomFields
                        value={values?.customFields}
                        onChange={(v) => setFieldValue("customFields", v)}
                      />
                    </div>
                  )}
                  {tab === "meta-data" && (
                    <div className="column is-half">
                      <MetaData meta={values?.meta} />
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
};

const Histories = ({
  boardColumns,
  histories,
}: {
  boardColumns: any;
  histories?: BoardItemHistory[];
}) => {
  if (!histories?.length) {
    return null;
  }
  return (
    <ul>
      {histories?.map((f, i) => (
        <li key={i}>
          <span>
            Move to <b>{boardColumns[f.currentStageId]}</b> at{" "}
            <b>{f.addedAt.toFormat("M/d/yyyy, h:mm a")}</b>
            {f.removedAt ? (
              <>
                {" "}
                and removed at <b>{f.removedAt.toFormat("M/d/yyyy, h:mm a")}</b>
              </>
            ) : (
              ""
            )}
          </span>
        </li>
      ))}
    </ul>
  );
};

export const AccountSelector = ({
  disabled,
  subcriber,
  name,
}: {
  name: string;
  disabled?: boolean;
  subcriber?: Chat;
}) => {
  const { t } = useTranslation();
  const [chatQuickView, setChatQuickView] = useState(0);
  const [open, setOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const { selectedBot } = useStore("bots");
  const [options, setOptions] = useState<
    { key?: string; label: React.ReactNode; value: string | number }[]
  >([]);
  const fetchRef = useRef(0);
  const debounceTimeout = 800;

  // const chatsTakenIds = useMemo(
  //   () =>
  //     boards
  //       ?.flatMap((f) => f.columns)
  //       ?.flatMap((f) => f.list)
  //       ?.map((f) => f.chat?.value) ?? [],
  //   [boards]
  // );

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      setOptions([]);

      if (!selectedBot || value.length <= 2) {
        return;
      }

      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setFetching(true);

      getContacts(
        selectedBot.id,
        selectedBot.key,
        selectedBot.password,
        value,
        undefined,
        1,
        0
      ).then((chats) => {
        chats = chats as Chat[];
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(
          chats.map((m) => ({
            label: `${m.firstName} ${m.lastName ?? ""} - ${
              channelMap[m.channel as never] ?? t(locale.other)
            } ${
              m.data?.e_cus_otherChannel ? `(${m.data.e_cus_otherChannel})` : ""
            }`,
            value: m.id,
            // disabled: chatsTakenIds.includes(m.id),
          }))
        );
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [selectedBot, t]);

  const openChat = (id: any) => {
    setChatQuickView(id);
  };

  useEffect(() => {
    if (subcriber?.firstName) {
      const name = `${subcriber.firstName} ${subcriber.lastName ?? ""} - ${
        channelMap[subcriber.channel as never] ?? t(locale.other)
      }`;
      setOptions([{ label: name, value: subcriber.id }]);
    }
  }, [subcriber, t]);

  return (
    <>
      {!open && (
        <AntSelect
          showSearch
          filterOption={false}
          name={name}
          label={
            <div>
              <span>{t(locale.contact)}</span>
              {subcriber?.id && (
                <a onClick={() => openChat(subcriber.id)} className="ml-1">
                  {t(locale.openChat)}
                </a>
              )}
            </div>
          }
          allowClear
          disabled={disabled}
          onSearch={debounceFetcher}
          // labelInValue
          notFoundContent={
            fetching ? (
              <Spin size="small" />
            ) : (
              <span className="px-2">{t(locale.notFound)}</span>
            )
          }
          options={options}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <div className="is-flex is-justify-content-center w-100">
                <Button
                  type="text"
                  className="is-flex is-align-items-center"
                  onClick={(e) => {
                    setOpen(true);
                  }}
                  icon={<FaPlusSquare />}
                >
                  {t(locale.create)}
                </Button>
              </div>
            </>
          )}
        />
      )}

      {open && <AddContact defaultOpen onClose={() => setOpen(false)} />}
      {chatQuickView > 0 && (
        <ChatQuickView
          chatId={chatQuickView}
          onClose={() => setChatQuickView(0)}
        />
      )}
    </>
  );
};

const StageSelector = ({
  disabled,
  onChange,
}: {
  disabled?: boolean;
  onChange?: (v: any) => void;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { selectedBoard } = useStore("boards");

  return (
    <>
      {!open && (
        <AntSelect
          showSearch
          filterOption={(input: any, option: any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          name="columnId"
          label={t(locale.stage)}
          allowClear
          disabled={disabled}
          options={
            selectedBoard?.columns?.map((f) => ({
              value: f.id,
              label: f.name,
            })) || []
          }
          onChange={onChange}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <div className="is-flex is-justify-content-center w-100">
                <Button
                  type="text"
                  className="is-flex is-align-items-center"
                  onClick={(e) => {
                    setOpen(true);
                  }}
                  icon={<FaPlusSquare />}
                >
                  {t(locale.create)}
                </Button>
              </div>
            </>
          )}
        />
      )}
      {open && (
        <ColumnModal isOpen onClose={() => setOpen(false)} mode={"add"} />
      )}
    </>
  );
};

export default observer(ItemModal);
