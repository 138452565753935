import { Button, Image, Space } from "antd";
import { useState } from "react";
import { BsDownload, BsZoomIn, BsZoomOut } from "react-icons/bs";

const imgStyle = {
  height: "100%",
  width: "100%",
  bottom: 0,
  left: 0,
  position: "absolute",
  right: 0,
  top: 0,
  maxHeight: "100%",
  objectFit: "cover",
  objectPosition: "center",
};

export default function MessageImage({
  src,
  meta,
}: {
  src: string;
  meta?: any;
}) {
  const [downloading, setDownloading] = useState(false);

  const onDownload = () => {
    if (src.startsWith("https://api.twilio.com/")) {
      window.open(src, "_blank");
      return;
    }
    setDownloading(true);
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "image.png";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      })
      .finally(() => {
        setDownloading(false);
      });
  };
  return (
    <>
      <Image
        wrapperStyle={{
          display: "block",
          paddingTop: "100%",
          position: "relative",
        }}
        className="has-rounded-corners"
        style={imgStyle as any}
        src={src}
        preview={{
          toolbarRender: (
            _,
            { transform: { scale }, actions: { onZoomOut, onZoomIn } }
          ) => (
            <Space
              size={12}
              className="has-background-black-ter has-rounded-corners p-2"
            >
              <Button
                ghost
                type="link"
                icon={<BsDownload color="white" size={26} />}
                onClick={onDownload}
                loading={downloading}
              />
              <Button
                ghost
                type="link"
                disabled={scale === 1}
                icon={<BsZoomOut color="white" size={26} />}
                onClick={onZoomOut}
              />
              <Button
                ghost
                type="link"
                disabled={scale === 50}
                icon={<BsZoomIn color="white" size={26} />}
                onClick={onZoomIn}
              />
            </Space>
          ),
        }}
      />
      {meta?.caption && <p>{meta.caption}</p>}
    </>
  );
}
