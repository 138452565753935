import clsx from "clsx";
import { Link, matchPath, useLocation } from "react-router-dom";
import { useStore } from "../../../stores";
import styles from "../NavBar.module.scss";
import { Collapse } from "../..";
import { Menu, SubChildMenu, SubMenu } from "../NavBar";
import { buildMenus, checkSubMenuPermission } from "../../../utils/menus";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import locale from "../../../constants/locale";
import routes from "../../../constants/routes";

const DesktopNavbar = () => {
  const { t } = useTranslation();
  const { workspaceLoaded, selectedFunction, selectedWorkspace } =
    useStore("workspaces");
  const { selectedBot, activeLiveChatCards } = useStore("bots");
  const { user } = useStore("auth");

  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: "/workspace/:workspaceId/:functionId",
    exact: false,
    strict: false,
  });

  const functionId = (match?.params as any)?.functionId as never;

  const isHelpCenterUserViewPage = location.pathname.includes(
    routes.helpCenterUserViewHome.replace(":botId", "")
  );

  const isAllAnalytics = location.pathname.includes("all-analytics");

  if (
    !workspaceLoaded ||
    !selectedWorkspace ||
    !["op"].includes(functionId) ||
    !selectedFunction ||
    !selectedBot ||
    !user ||
    isHelpCenterUserViewPage ||
    isAllAnalytics
  ) {
    return null;
  }

  const menus = buildMenus(
    selectedWorkspace?.id,
    selectedFunction.slug,
    location,
    activeLiveChatCards
  );

  if (!selectedBot) {
    return null;
  }

  return (
    <div
      className={clsx(
        "is-flex is-flex-direction-column is-justify-content-stretch has-border-right",
        styles.container,
        styles.opened
      )}
    >
      <div className="is-flex is-flex-direction-column is-justify-content-stretch is-flex-grow-1">
        <div className="has-border-bottom">
          <div
            className={clsx(
              "navbar-item is-flex mx-4 is-align-items-center is-justify-content-space-between",
              styles.header
            )}
          >
            <h3 className="title is-5">{t(selectedFunction?.name || "")}</h3>
          </div>
        </div>

        <div className={clsx(styles.sidebarBg, "py-5")}>
          {/* <BotSwitcher opened={true} /> */}
          <div className="is-flex is-flex-direction-column is-justify-content-stretch is-flex-grow-1">
            {menus.map((menu, i) => (
              <RenderMenu key={i} menu={menu} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

function RenderMenu({ menu }: { menu: Menu }) {
  const { selectedBot } = useStore("bots");
  const { selectedWorkspace, selectedFunction } = useStore("workspaces");
  const { user } = useStore("auth");
  const { t } = useTranslation();

  if (!selectedWorkspace || !selectedFunction || !selectedBot || !user) {
    return null;
  }

  if (
    !menu.children.some((subMenu) =>
      checkSubMenuPermission(selectedWorkspace, selectedFunction, user, subMenu)
    )
  )
    return null;

  return (
    <Collapse
      defaultExpanded={menu.children.some((f) => f.active)}
      key={selectedBot.id + "-" + menu.text}
      renderTrigger={(trigger) => (
        <div
          key={menu.text}
          {...trigger}
          className={clsx(
            styles.sidebarMenu,
            "op-" + menu.text.toLowerCase(),
            "navbar-item is-flex is-justify-content-space-between has-rounded-corners mx-5",
            {
              [styles.sidebarMenuActive]: menu.children.some((f) => f.active),
            }
          )}
          style={{ cursor: "pointer" }}
        >
          <span>{t(menu.text)}</span>

          {menu.text === locale.teams && (
            <RedDotForTeams isCollapse={true} menu={menu} />
          )}
          <div className="icon">
            {trigger.isExpanded ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="16"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                  clip-rule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                width="16"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clip-rule="evenodd"
                />
              </svg>
            )}
          </div>
        </div>
      )}
    >
      <div className="mx-4">
        {menu.children.map((subMenu) => {
          if (
            !checkSubMenuPermission(
              selectedWorkspace,
              selectedFunction,
              user,
              subMenu
            )
          )
            return null;

          if (subMenu.children) {
            return (
              <Collapse
                key={selectedBot.id + "-" + subMenu.text}
                renderTrigger={(trigger) => (
                  <div
                    key={subMenu.text}
                    {...trigger}
                    className="navbar-item is-flex has-rounded-corners mx-5"
                  >
                    <div
                      title={t(subMenu.text)}
                      className={clsx(
                        "is-clickable is-flex is-align-items-center",
                        styles.sidebarChildrenItem
                      )}
                    >
                      <span className={clsx("icon mr-2", styles.icon)}>
                        <img
                          src={subMenu.icon}
                          className={subMenu?.iconClass}
                          alt={subMenu.text}
                          width={20}
                        />
                      </span>
                      {subMenu.isEllipsis ? (
                        <span className="has-ellipsis-text">
                          {t(subMenu.text)}
                        </span>
                      ) : (
                        t(subMenu.text)
                      )}
                      {menu.text === locale.teams && (
                        <RedDotForTeams subMenu={subMenu} />
                      )}
                    </div>
                  </div>
                )}
              >
                {subMenu.children.map((childMenu, i) => (
                  <Link
                    key={i}
                    className={clsx(
                      styles.sidebarItem,
                      "navbar-item is-flex has-rounded-corners mx-5",
                      {
                        "has-text-primary": childMenu.active,
                        "has-text-grey": !childMenu.active,
                      }
                    )}
                    style={{ paddingLeft: "3rem", height: "2rem" }}
                    to={childMenu.to || ""}
                  >
                    <span style={{ fontSize: 12 }}>{t(childMenu.text)}</span>
                    {menu.text === locale.teams &&
                      [locale.connected, locale.requested].includes(
                        childMenu.text
                      ) && <CountForLiveCard childMenu={childMenu} />}
                    {menu.text === locale.teams &&
                      childMenu.text === locale.tickets && (
                        <CountForTickets childMenu={childMenu} />
                      )}
                    {[
                      locale.groupChats,
                      locale.tickets,
                      locale.requested,
                    ].includes(childMenu.text) && (
                      <RedDotForTeams childMenu={childMenu} />
                    )}
                  </Link>
                ))}
              </Collapse>
            );
          }

          return (
            <Link
              key={subMenu.to}
              className={clsx(
                styles.sidebarItem,
                "op-" +
                  menu.text.toLowerCase() +
                  "-" +
                  subMenu.text.toLowerCase(),
                "navbar-item is-flex has-rounded-corners mx-5",
                {
                  "has-text-primary": subMenu.active,
                }
              )}
              to={subMenu.to || ""}
            >
              <div className="is-flex is-align-items-center">
                <span className={clsx("icon mr-2", styles.icon)}>
                  <img
                    src={subMenu.icon}
                    className={subMenu?.iconClass}
                    alt={subMenu.text}
                    width={20}
                  />
                </span>
                {t(subMenu.text)}
              </div>
              <RedDotForInbox subMenu={subMenu} />
              {/* <RedDotForTeammate menu={menu} subMenu={subMenu} /> */}
            </Link>
          );
        })}
      </div>
    </Collapse>
  );
}

interface RedDotBadgeProps {
  isCollapse?: boolean;
  hasNewMessages: boolean;
}

const RedDotBadge: React.FC<RedDotBadgeProps> = ({
  isCollapse,
  hasNewMessages,
}) => {
  if (hasNewMessages) {
    return (
      <span
        className={clsx("badge is-right mr-2 is-danger", {
          "mr-5": isCollapse,
        })}
      ></span>
    );
  }
  return null;
};

interface RedDotForInboxProps {
  subMenu: SubMenu; // Replace with the actual type of SubMenu
}

const RedDotForInbox: React.FC<RedDotForInboxProps> = observer(
  ({ subMenu }) => {
    const { chatsCount } = useStore("chats");

    let matchText = subMenu.text;

    if (matchText === locale.myQueue) {
      matchText = "myQueueNewMessageCount";
    }

    if (matchText === locale.snoozed) {
      matchText = "snoozedNewMessageCount";
    }

    const hasNewMessages =
      [
        locale.myQueue,
        locale.requested,
        locale.connected,
        locale.snoozed,
      ].includes(subMenu.text) && chatsCount[matchText] > 0;

    return <RedDotBadge hasNewMessages={hasNewMessages} />;
  }
);

interface RedDotForTeamsProps {
  isCollapse?: boolean;
  menu?: any; // Replace 'any' with a more specific type if available
  subMenu?: any; // Replace 'any' with a more specific type if available
  childMenu?: any; // Replace 'any' with a more specific type if available
}

const RedDotForTeams: React.FC<RedDotForTeamsProps> = observer(
  ({ isCollapse, menu, subMenu, childMenu }) => {
    const { newMessageAlerts } = useStore("ednaChats");
    const { chatsCount } = useStore("chats");
    const { userTicketsCount } = useStore("boards");

    const callbacks = {
      requested: (to: string) => {
        const groupId = new URLSearchParams(to).get("group") ?? "0";
        return (chatsCount?.requestedGroups?.[groupId] ?? 0) > 0;
      },
      tickets: (to: string) => {
        const groupId = parseInt(to.split("/").pop() || "0");
        return (userTicketsCount?.[groupId] ?? 0) > 0;
      },
      groupChats: (to: string) => {
        const chatId = to.split("/").pop() || "";
        return newMessageAlerts.includes(chatId);
      },
    };

    const checkNewNotification = (
      items: any[] | undefined,
      filter: string[] = ["requested", "tickets", "groupChats"]
    ) => {
      return (
        items
          ?.filter((item) => filter.includes(item.text))
          .some((item) =>
            callbacks[item.text as keyof typeof callbacks](item.to)
          ) ?? false
      );
    };

    const hasNewNotification = (() => {
      if (menu) {
        return checkNewNotification(
          menu.children?.flatMap((f: any) => f.children ?? [])
        );
      } else if (subMenu) {
        return checkNewNotification(subMenu.children);
      } else if (childMenu) {
        const menuText = childMenu.text;
        if (
          menuText === "requested" ||
          menuText === locale.tickets ||
          menuText === locale.groupChats
        ) {
          return callbacks[menuText as keyof typeof callbacks](childMenu.to);
        }
      }
      return false;
    })();

    return (
      <RedDotBadge
        isCollapse={isCollapse}
        hasNewMessages={hasNewNotification}
      />
    );
  }
);

const CountForLiveCard = observer(
  ({ childMenu }: { childMenu: SubChildMenu }) => {
    const { chatsCount } = useStore("chats");
    const params = new URLSearchParams(
      childMenu.to.substring(childMenu.to.indexOf("?"))
    );
    const tab = params.get("tab");
    const groupId = parseInt(params.get("group") || "");
    const key = tab + "Groups";

    return <span className="ml-2">({chatsCount[key]?.[groupId] || 0})</span>;
  }
);

const CountForTickets = observer(
  ({ childMenu }: { childMenu: SubChildMenu }) => {
    const { userTicketsCount } = useStore("boards");
    const groupId = parseInt(childMenu.to.split("/").pop() || "");

    return <span className="ml-2">({userTicketsCount[groupId] || 0})</span>;
  }
);

export default observer(DesktopNavbar);
