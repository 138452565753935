import { Popover } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores";
import { FallbackImage } from "../fallbackImage/FallbackImage";
import { userStatusColorMap, userTypeMap } from "../../models";
import ReactLinkify from "react-linkify";
import { useTranslation } from "react-i18next";

const Mentionify = ({
  children,
  replaceName,
  noAtTheRate = false,
}: {
  children: any;
  noAtTheRate?: boolean;
  replaceName?: boolean;
}) => {
  const regex = /@\[([^\]]+)\]\(([^)]+)\)/g;

  const result = String(children)
    ?.split(/(@\[.*?\]\([^)]+\))/)
    ?.map((part: any, index: number) => {
      const match = regex.exec(part);
      if (match) {
        const [, userName, userId] = match;
        return (
          <MentionDecorator
            key={index}
            userName={userName}
            userId={userId}
            noAtTheRate={noAtTheRate}
            replaceName={replaceName}
          />
        );
      } else {
        return <RenderLinks part={part} />;
      }
    });

  return <div>{result}</div>;
};

const MentionDecorator = observer(
  ({
    userId,
    userName,
    noAtTheRate,
    replaceName,
  }: {
    userName: string;
    userId: string;
    noAtTheRate?: boolean;
    replaceName?: boolean;
  }) => {
    const { t } = useTranslation();
    const { users } = useStore("users");
    const user = users?.find((u) => u.id === userId);

    const content = (
      <div className="is-flex is-align-items-cente">
        <figure className="is-flex-shrink-0 mr-2">
          <p className="has-rounded-corners image is-64x64 is-square">
            <FallbackImage
              src={user?.avatarUrl}
              fallback={"/assets/avatar.svg"}
              alt={user?.name}
              title={user?.name}
            />
          </p>
        </figure>
        <div>
          <div className="mt-2 is-flex mb-0">
            <h4 className="title is-6 mb-0 has-text-weight-bold">
              {user?.name}
            </h4>
            {user?.status && (
              <div
                className="ml-1"
                style={{
                  borderRadius: "50%",
                  background: userStatusColorMap[user.status as never],
                  height: "0.5rem",
                  width: "0.5rem",
                }}
              />
            )}
          </div>
          <h4 className="subtitle is-7 mt-1 mb-0 has-text-weight-semibold">
            {user?.email}
          </h4>
          <h4 className="subtitle is-7 mt-1">
            {t(userTypeMap[user?.type as never])}
          </h4>
        </div>
      </div>
    );

    return (
      <Popover zIndex={99999999999} content={user ? content : undefined}>
        <span
          style={{
            background: "rgb(181 210 254 / 44%)",
          }}
          className="is-clickable has-rounded-corners px-1"
          data-user-id={userId}
        >
          {noAtTheRate ? "" : "@"}
          {replaceName ? user?.name : userName}
        </span>
      </Popover>
    );
  }
);

const RenderLinks = ({ part }: { part?: string }) => {
  const text = part
    ?.split(/(<a\s+(?:[^>]*?\s+)?href="[^"]*">[^<]*<\/a>)/)
    ?.map((part: any) => {
      const extractLinks = (text: string) => {
        const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)">([^<]*)<\/a>/g;
        const matches = text.matchAll(linkRegex);
        return Array.from(matches, (match) => ({
          url: match[1],
          text: match[2],
        }));
      };
      const urls = extractLinks(part);

      if (urls.length > 0) {
        return urls.map((link, index) => (
          <a key={index} href={link.url}>
            {link.text}
          </a>
        ));
      }

      return (
        <ReactLinkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              href={decoratedHref}
              key={key}
              target="_blank"
              rel="noopener noreferrer"
            >
              {decoratedText}
            </a>
          )}
        >
          {part}
        </ReactLinkify>
      );
    });

  return <>{text}</>;
};

export default Mentionify;
