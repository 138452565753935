import firebaseApp from "firebase/compat/app";
import { Schedule } from "./Schedule";
import locale from "../constants/locale";

export enum EventDuration {
  _15m = 15,
  _30m = 30,
  _40m = 40,
  _1h = 60,
  _2h = 120,
  _4h = 240,
  _8h = 480,
}

export const eventDurationMap: Record<EventDuration, string> = {
  [EventDuration._15m]: "15 minutes",
  [EventDuration._30m]: "30 minutes",
  [EventDuration._40m]: "40 minutes",
  [EventDuration._1h]: "1 hour",
  [EventDuration._2h]: "2 hours",
  [EventDuration._4h]: "4 hours",
  [EventDuration._8h]: "8 hours",
};

export type Event = {
  id?: string;
  name: string;
  color?: string;
  description: string;
  location: string;
  livecardId: number; //used for booking
  livecardName: string; //used for booking
  livecards?: { label: string; value: number }[];
  duration: EventDuration;
  isActive: boolean;
  isArchived?: boolean;
  labelId?: string;
  allowConcurrentEvents?: boolean;
  noOfConcurrentEvents?: number;
  createdAt: firebaseApp.firestore.FieldValue;
  updatedAt: firebaseApp.firestore.FieldValue;
  calendarSettings?: CalenderSettings;
  integration?: "outlook" | "google";
};

export type CalenderSettings = {
  schedule?: Schedule;
  timezone?: string;
  bookingForm?: {
    name: boolean;
    email: boolean;
    phone: boolean;
    notes: boolean;
  };
  bookingFormMatching?: {
    name: string;
    email: string;
    phone: string;
    notes: string;
  };
  bookingFormCustomFields: {
    name: string;
    type: string;
    matching?: string;
    meta?: any;
  }[];
};

export const bookingFormFields = [
  {
    key: "name",
    label: locale.name,
  },
  {
    key: "email",
    label: locale.email,
  },
  {
    key: "phone",
    label: locale.phone,
  },
  {
    key: "notes",
    label: locale.notes,
  },
];
