import { action, makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";
import {
  MenuItem,
  MenuExclusion,
  MenuCategory,
  MenuModifier,
  CategoryPromotion,
} from "../models";
import { logError } from "../services/logging";
import { uploadImage, FirebaseSubscriber } from "./utils";
import { compare } from "../utils/arrays";
import restaurants from "./restaurants";
import axios from "axios";
import bots from "./bots";

class MenuStore {
  private db: firebase.firestore.Firestore;
  private menuCategoriesSubscriber?: FirebaseSubscriber;
  private menuItemsSubscriber?: FirebaseSubscriber;
  private menuItemsExclusionsSubscriber?: FirebaseSubscriber;
  private menuModifiersSubscriber?: FirebaseSubscriber;

  menuCategories: MenuCategory[] = [];
  menuItems: MenuItem[] = [];
  menuItem?: MenuItem;
  menuExclusions: MenuExclusion[] = [];
  menuModifiers: MenuModifier[] = [];
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);

    this.db = firebase.firestore();
  }

  @action.bound
  loadCategories = () => {
    try {
      if (
        !restaurants.selectedRestaurant ||
        this.menuCategoriesSubscriber?.id === restaurants.selectedRestaurant.id
      ) {
        return;
      }

      this.isLoading = true;
      let initial = true;

      if (this.menuCategoriesSubscriber) {
        this.menuCategoriesSubscriber.unsubscribe();
        this.menuCategoriesSubscriber = undefined;
        this.menuCategories = [];
      }

      this.menuCategoriesSubscriber = {
        id: restaurants.selectedRestaurant.id,
        unsubscribe: this.db
          .collection("restaurants")
          .doc(restaurants.selectedRestaurant.id)
          .collection("categories")
          .onSnapshot((snap) => {
            if (snap.empty) {
              this.menuCategories = [];
              return;
            }

            let temp = initial ? [] : [...this.menuCategories];

            snap.docChanges().forEach((change) => {
              const data = change.doc.data();
              switch (change.type) {
                case "added":
                  temp.push(this.categoryAdapter(data));
                  temp = temp.sort(compare((a) => a.name));
                  break;
                case "modified":
                  temp = temp.map((f) =>
                    f.id === data.id ? this.categoryAdapter(data) : f
                  );
                  break;
                case "removed":
                  temp = temp.filter((f) => f.id !== data.id);
                  break;
              }
            });

            if (initial) {
              this.isLoading = false;
              initial = false;
            }
            this.menuCategories = temp.sort(
              (a, b) => (a.order || 0) - (b.order || 0)
            );
          }, logError),
      };
    } catch (error) {
      logError(error);
      this.isLoading = false;
    }
  };

  @action.bound
  loadMenuItems = () => {
    if (
      !restaurants.selectedRestaurant ||
      this.menuItemsSubscriber?.id === restaurants.selectedRestaurant.id
    ) {
      return;
    }

    this.isLoading = true;
    let initial = true;

    if (this.menuItemsSubscriber) {
      this.menuItemsSubscriber.unsubscribe();
      this.menuItemsSubscriber = undefined;
      this.menuItems = [];
    }

    try {
      this.menuItemsSubscriber = {
        id: restaurants.selectedRestaurant.id,
        unsubscribe: this.db
          .collection("restaurants")
          .doc(restaurants.selectedRestaurant.id)
          .collection("menuItems")
          .orderBy("name")
          .onSnapshot((snap) => {
            if (snap.empty) {
              this.menuItems = [];
              return;
            }

            let temp = initial ? [] : [...this.menuItems];

            snap.docChanges().forEach((change) => {
              const data = change.doc.data();
              switch (change.type) {
                case "added":
                  temp.push(this.menuAdapter(data));
                  temp = temp.sort(compare((a) => a.name));
                  break;
                case "modified":
                  temp = temp.map((f) =>
                    f.id === data.id ? this.menuAdapter(data) : f
                  );
                  break;
                case "removed":
                  temp = temp.filter((f) => f.id !== data.id);
                  break;
              }
            });

            if (initial) {
              this.isLoading = false;
              initial = false;
            }
            this.menuItems = temp;
          }, logError),
      };
    } catch (error) {
      logError(error);
      this.isLoading = false;
    }
  };

  @action.bound
  loadExclusions = () => {
    if (
      !restaurants.selectedRestaurant ||
      !restaurants.selectedBranch ||
      this.menuItemsExclusionsSubscriber?.id === restaurants.selectedBranch.id
    ) {
      return;
    }

    this.isLoading = true;
    let initial = true;

    if (this.menuItemsExclusionsSubscriber) {
      this.menuItemsExclusionsSubscriber.unsubscribe();
      this.menuItemsExclusionsSubscriber = undefined;
      this.menuExclusions = [];
    }

    try {
      this.menuItemsExclusionsSubscriber = {
        id: restaurants.selectedBranch.id,
        unsubscribe: this.db
          .collection("restaurants")
          .doc(restaurants.selectedRestaurant.id)
          .collection("branches")
          .doc(restaurants.selectedBranch.id)
          .collection("exclusions")
          .onSnapshot((snap) => {
            if (snap.empty) {
              return;
            }

            let temp = initial ? [] : [...this.menuExclusions];

            snap.docChanges().forEach((change) => {
              const data = change.doc.data();
              switch (change.type) {
                case "added":
                  temp.push(this.exclusionAdapter(data));
                  break;
                case "modified":
                  temp = temp.map((f) =>
                    f.id === data.id ? this.exclusionAdapter(data) : f
                  );
                  break;
                case "removed":
                  temp = temp.filter((f) => f.id !== data.id);
                  break;
              }
            });

            if (initial) {
              this.isLoading = false;
              initial = false;
            }
            this.menuExclusions = temp;
          }, logError),
      };
    } catch (error) {
      logError(error);
      this.isLoading = false;
    }
  };

  @action.bound
  loadModifiers = () => {
    if (
      !restaurants.selectedRestaurant ||
      this.menuModifiersSubscriber?.id === restaurants.selectedRestaurant.id
    ) {
      return;
    }

    this.isLoading = true;
    let initial = true;

    if (this.menuModifiersSubscriber) {
      this.menuModifiersSubscriber.unsubscribe();
      this.menuModifiersSubscriber = undefined;
      this.menuModifiers = [];
    }

    try {
      this.menuModifiersSubscriber = {
        id: restaurants.selectedRestaurant.id,
        unsubscribe: this.db
          .collection("restaurants")
          .doc(restaurants.selectedRestaurant.id)
          .collection("menuModifiers")
          .orderBy("name")
          .onSnapshot((snap) => {
            if (snap.empty) {
              this.menuModifiers = [];
              return;
            }

            let temp = initial ? [] : [...this.menuModifiers];

            snap.docChanges().forEach((change) => {
              const data = change.doc.data();
              switch (change.type) {
                case "added":
                  temp.push(this.modifierAdapter(data));
                  temp = temp.sort(compare((a) => a.name));
                  break;
                case "modified":
                  temp = temp.map((f) =>
                    f.id === data.id ? this.modifierAdapter(data) : f
                  );
                  break;
                case "removed":
                  temp = temp.filter((f) => f.id !== data.id);
                  break;
              }
            });

            if (initial) {
              this.isLoading = false;
              initial = false;
            }
            this.menuModifiers = temp;
          }, logError),
      };
    } catch (error) {
      logError(error);
      this.isLoading = false;
    }
  };

  @action.bound
  loadMenuItem = async (id: string) => {
    if (!restaurants.selectedRestaurant) {
      return false;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("menuItems")
        .doc(id);

      const snap = await ref.get();
      const data = snap.data();

      this.menuItem = data ? this.menuAdapter(data) : undefined;

      return !!this.menuItem;
    } catch (error) {
      logError(error, id);
    } finally {
      this.isLoading = false;
    }
    return false;
  };

  @action.bound
  createCategory = async ({
    image,
    ...data
  }: Omit<
    MenuCategory,
    "id" | "image" | "promotion" | "createdAt" | "updatedAt"
  > & {
    image?: File;
    promotion?: Omit<CategoryPromotion, "bannerImage"> & {
      bannerImage?: File | string;
    };
  }) => {
    if (!restaurants.selectedRestaurant || !this.menuCategoriesSubscriber) {
      logError(
        "there is no restaurant selected or or categories subscriber active",
        data
      );
      return false;
    }

    if (
      this.menuCategories.some(
        (s) =>
          s.name.toLowerCase() === data.name.toLowerCase() &&
          s.parentId === data.parentId
      )
    ) {
      logError("Already exists menu category", data);
      return false;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("categories")
        .doc();
      const category = {
        ...data,
        id: ref.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      };

      if (image) {
        const img = await uploadImage(image, ref.id);
        if (img) (category as any).image = img;
      } else {
        (category as any).image = process.env.REACT_APP_CATEGORY_IMAGE_DEFAULT;
      }

      if (category.promotion?.bannerImage instanceof File) {
        const img = await uploadImage(
          category.promotion.bannerImage,
          `${ref.id}_banner`
        );
        if (img) {
          (category as any).promotion.bannerImage = img;
        }
      }

      await ref.set(category);

      return true;
    } catch (error) {
      logError(error, data);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  createMenuItem = async ({
    image,
    ...data
  }: Omit<MenuItem, "id" | "image" | "createdAt" | "updatedAt"> & {
    image: File;
  }) => {
    if (!restaurants.selectedRestaurant) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("menuItems")
        .doc();

      const img = await uploadImage(image, ref.id);
      if (!img) {
        throw new Error("Error uploading image");
      }

      const menuItem: MenuItem = {
        ...data,
        id: ref.id,
        image: img,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      };

      await ref.set(menuItem);

      if (restaurants.selectedRestaurant.metaCatalogId) {
        await this.syncMetaCatalogItem(
          "CREATE",
          ref.id,
          this.metaCatalogProductAdapter(menuItem)
        );
      }

      return true;
    } catch (error) {
      logError(error, data);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  createExclusion = async (
    data: Omit<MenuExclusion, "id" | "createdAt" | "updatedAt">
  ) => {
    if (!restaurants.selectedRestaurant || !restaurants.selectedBranch) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("branches")
        .doc(restaurants.selectedBranch.id)
        .collection("exclusions")
        .doc();
      const exclusion: MenuExclusion = {
        ...data,
        id: ref.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      };

      await ref.set(exclusion);

      return true;
    } catch (error) {
      logError(error, data);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  createMenuModifier = async (
    data: Omit<MenuModifier, "id" | "createdAt" | "updatedAt">
  ) => {
    if (!restaurants.selectedRestaurant) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("menuModifiers")
        .doc();
      const menuModifier: MenuModifier = {
        ...data,
        id: ref.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      };

      await ref.set(menuModifier);

      return true;
    } catch (error) {
      logError(error, data);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  updateCategory = async ({
    image,
    createdAt,
    ...data
  }: Omit<MenuCategory, "image" | "promotion" | "updatedAt"> & {
    image?: File;
    promotion: Omit<CategoryPromotion, "bannerImage"> & {
      bannerImage?: File | string;
    };
  }) => {
    if (!restaurants.selectedRestaurant) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("categories")
        .doc(data.id);
      const category = {
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      if (image) {
        const img = await uploadImage(image, ref.id);
        if (img) {
          (category as any).image = img;
        }
      }

      if (category.promotion.bannerImage instanceof File) {
        const img = await uploadImage(
          category.promotion.bannerImage,
          `${ref.id}_banner`
        );
        if (img) {
          (category as any).promotion.bannerImage = img;
        }
      }

      await ref.update(category);

      return true;
    } catch (error) {
      logError(error, data);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  updateMenuItem = async ({
    image,
    createdAt,
    ...data
  }: Omit<MenuItem, "image" | "updatedAt"> & {
    image?: File;
  }) => {
    if (!restaurants.selectedRestaurant) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("menuItems")
        .doc(data.id);

      const item = (await ref.get()).data() as MenuItem;

      const menuItem = {
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      if (image) {
        const img = await uploadImage(image, ref.id);
        if (!img) {
          throw new Error("Error uploading image");
        }
        (menuItem as any).image = img;
      } else {
        (menuItem as any).image = item.image;
      }

      await ref.update(menuItem);

      if (restaurants.selectedRestaurant.metaCatalogId) {
        await this.syncMetaCatalogItem(
          "UPDATE",
          data.id,
          this.metaCatalogProductAdapter(menuItem as any)
        );
      }

      return true;
    } catch (error) {
      logError(error, data);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  updateExclusion = async ({
    createdAt,
    ...data
  }: Omit<MenuExclusion, "updatedAt">) => {
    if (!restaurants.selectedRestaurant || !restaurants.selectedBranch) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("branches")
        .doc(restaurants.selectedBranch.id)
        .collection("exclusions")
        .doc(data.id);
      const branch = {
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await ref.update(branch);

      return true;
    } catch (error) {
      logError(error, data);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  updateMenuModifier = async ({
    createdAt,
    ...data
  }: Omit<MenuModifier, "updatedAt">) => {
    if (!restaurants.selectedRestaurant) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("menuModifiers")
        .doc(data.id);
      const menuModifier = {
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await ref.update(menuModifier);

      return true;
    } catch (error) {
      logError(error, data);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  deleteExclusion = async (id: string) => {
    if (!restaurants.selectedRestaurant || !restaurants.selectedBranch) {
      return;
    }

    this.isLoading = true;

    try {
      const ref = this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("branches")
        .doc(restaurants.selectedBranch.id)
        .collection("exclusions")
        .doc(id);

      await ref.delete();

      this.menuExclusions = this.menuExclusions.filter((f) => f.id !== id);

      return true;
    } catch (error) {
      logError(error, id);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  deleteMenuItem = async (id: string) => {
    if (!restaurants.selectedRestaurant) {
      return;
    }

    this.isLoading = true;

    try {
      await this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("menuItems")
        .doc(id)
        .delete();

      if (restaurants.selectedRestaurant.metaCatalogId) {
        await this.syncMetaCatalogItem("DELETE", id);
      }

      return true;
    } catch (error) {
      logError(error, id);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  deleteMenuCategory = async (id: string) => {
    if (!restaurants.selectedRestaurant) {
      return;
    }

    this.isLoading = true;

    try {
      await this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("categories")
        .doc(id)
        .delete();

      return true;
    } catch (error) {
      logError(error, id);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  @action.bound
  deleteMenuModifier = async (id: string) => {
    if (!restaurants.selectedRestaurant) {
      return;
    }

    this.isLoading = true;

    try {
      await this.db
        .collection("restaurants")
        .doc(restaurants.selectedRestaurant.id)
        .collection("menuModifiers")
        .doc(id)
        .delete();

      return true;
    } catch (error) {
      logError(error, id);
    } finally {
      this.isLoading = false;
    }

    return false;
  };

  syncMetaCatalog = async () => {
    const payload = {
      allowUpsert: true,
      item_type: "PRODUCT_ITEM",
      requests: this.menuItems.map((item) => ({
        method: "UPDATE",
        retailer_id: item.id,
        data: this.metaCatalogProductAdapter(item),
      })),
    };
    await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/meta-catalog/batch`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        catalogId: restaurants.selectedRestaurant?.metaCatalogId,
        payload,
      }
    );
  };

  syncMetaCatalogItem = async (
    method: "UPDATE" | "CREATE" | "DELETE",
    productId: string,
    data?: any
  ) => {
    try {
      const payload = {
        requests: [
          {
            method,
            retailer_id: productId,
            data: data,
          },
        ],
      };
      await axios.post(
        `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/meta-catalog/batch`,
        {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          catalogId: restaurants.selectedRestaurant?.metaCatalogId,
          payload,
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  getMetaCatalogProducts = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/meta-catalog/${restaurants.selectedRestaurant?.metaCatalogId}/products`,
        {
          params: {
            key: bots.selectedBot?.key,
            password: bots.selectedBot?.password,
          },
        }
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  private categoryAdapter = (data: firebase.firestore.DocumentData) =>
    ({
      ...data,
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date(),
    } as MenuCategory);

  private menuAdapter = (data: firebase.firestore.DocumentData) =>
    ({
      ...data,
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date(),
    } as MenuItem);

  private modifierAdapter = (data: firebase.firestore.DocumentData) =>
    ({
      ...data,
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date(),
    } as MenuModifier);

  private exclusionAdapter = (data: firebase.firestore.DocumentData) =>
    ({
      ...data,
      createdAt: data.createdAt?.toDate() || new Date(),
      updatedAt: data.updatedAt?.toDate() || new Date(),
    } as MenuExclusion);

  private metaCatalogProductAdapter = (data: MenuItem) => ({
    availability: (data.stock as any).replaceAll("_", " "),
    description: data.description,
    image_url: data.image,
    name: data.name,
    price: data.price * 100,
    quantity_to_sell_on_facebook: data.quantity,
    currency: restaurants.selectedRestaurant?.currency ?? "USD",
    url: "https://app.edna.ai/buy-now",
  });
}

export default new MenuStore();
