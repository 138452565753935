import clsx from "clsx";
import { useField, FieldConfig, useFormikContext } from "formik";
import { TFunctionResult } from "i18next";
import { Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

export interface FieldNames {
  value?: string;
  label?: string;
  groupLabel?: string;
  options?: string;
}

export const AntSelect = <T, V>({
  onChange,
  allowClear,
  hideLabel,
  className,
  children,
  multiple,
  fieldNames,
  size,
  style,
  showSearch,
  filterOption,
  placeholder,
  ...props
}: FieldConfig<V> & {
  loading?: boolean;
  className?: string;
  controlClass?: string;
  labelClass?: string;
  errorClass?: string;
  disabled?: boolean;
  label?: React.ReactChild | TFunctionResult;
  options: any[];
  allowClear?: boolean;
  hideLabel?: boolean;
  getLabel?: (t: T) => string;
  getValue?: (t: T) => V;
  onChange?: (v: V) => void;
  extras?: React.ReactChild;
  labelIsNotAOption?: boolean;
  multiple?: boolean;
  fieldNames?: FieldNames;
  size?: SizeType;
  style?: React.CSSProperties;
  showSearch?: boolean;
  filterOption?: boolean | any;
  placeholder?: string;
  onSearch?: (value: string) => void;
  notFoundContent?: React.ReactChild | TFunctionResult;
  labelInValue?: boolean;
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement;
}) => {
  const [field, { value, touched, error }, { setValue }] = useField(props);
  const { submitCount } = useFormikContext();
  const label = typeof props.label === "function" ? props.label() : props.label;
  const isTouched = touched || submitCount > 0;

  return (
    <div className="field">
      {!hideLabel && label && (
        <label className={clsx("label", props.labelClass)}>{label}</label>
      )}
      <div
        className={clsx("", className, {
          "is-danger": touched && error,
        })}
      >
        <Select
          {...field}
          {...props}
          loading={props.loading}
          onSearch={props.onSearch}
          notFoundContent={props.notFoundContent as never}
          labelInValue={props.labelInValue}
          dropdownRender={props.dropdownRender}
          allowClear={allowClear}
          size={size || "large"}
          placeholder={placeholder}
          showSearch={showSearch}
          className="w-100"
          filterOption={filterOption as never}
          style={style}
          options={props.options}
          popupClassName={clsx({
            "ant-select-custom-popup": size === "small",
          })}
          mode={multiple ? "multiple" : undefined}
          onChange={(e) => {
            setValue(e as any);
            if (onChange) {
              onChange(e as any);
            }
          }}
          value={value}
          fieldNames={fieldNames}
        />
      </div>
      {isTouched && !!error && (
        <p className={clsx("help is-danger", props.errorClass)}>{error}</p>
      )}
      {props.extras && props.extras}
    </div>
  );
};
