import { lazy } from "react";

import routes, { webViewRoutes } from "../constants/routes";
import { ComponentLoader } from "../components/componentLoader/ComponentLoader";

export const getPage = (route: string) => {
  let loader: () => Promise<any>;

  switch (route) {
    case routes.notFound:
      loader = () => import("./notFound/NotFound");
      break;
    case routes.redirectCallback:
      loader = () => import("./redirectCallback/RedirectCallback");
      break;
    case routes.helpCenterUserViewHome:
      loader = () => import("./helpCenterUserView/pages/home/Home");
      break;
    case routes.helpCenterUserViewCollection:
      loader = () => import("./helpCenterUserView/pages/collection/Collection");
      break;
    case routes.helpCenterUserViewArticle:
      loader = () => import("./helpCenterUserView/pages/article/Article");
      break;
    case routes.helpCenterUserViewSearch:
      loader = () => import("./helpCenterUserView/pages/search/Search");
      break;
    case routes.booking:
      loader = () => import("./booking/Booking");
      break;
    case routes.signUp:
      loader = () => import("./signUp/SignUp");
      break;
    case routes.loginOAuthAuthorize:
      loader = () => import("./signIn/OAuthAuthorize");
      break;
    case routes.signIn:
      loader = () => import("./signIn/SignIn");
      break;
    case routes.forgotPassword:
      loader = () => import("./forgotPassword/ForgotPassword");
      break;
    case routes.linkBot:
      loader = () => import("./linkBot/LinkBot");
      break;
    case routes.createWorkspace:
      loader = () => import("./workspaces/CreateWorkspace");
      break;
    case routes.noWorkspace:
      loader = () => import("./noWorkspace/NoWorkspace");
      break;
    case routes.workspaceJoin:
      loader = () => import("./workspaceJoin/WorkspaceJoin");
      break;
    case routes.workspaceNoAccess:
      loader = () => import("./workspaceNoAccess/WorkspaceNoAccess");
      break;
    case routes.workspaceHome:
      loader = () => import("./workspaces/WorkspaceHome");
      break;
    case routes.workspaceSettings:
      loader = () => import("./workspaces/WorkspaceSettings");
      break;
    case routes.joinWorkspace:
      loader = () => import("./joinWorkspace/JoinWorkspace");
      break;
    case routes.chats:
      loader = () => import("./chats/Chats");
      break;
    case routes.groupChats:
      loader = () => import("./groupChats/GroupChats");
      break;
    case routes.groupCalendar:
      loader = () => import("./calendar/Calendar");
      break;
    case routes.aITeamChats:
      loader = () => import("./aITeamChats/AITeamChats");
      break;
    case routes.teammates:
      loader = () => import("./teammates/Teammates");
      break;
    case routes.tickets:
      loader = () => import("./chats/TicketsV2/TicketsV2");
      break;
    case routes.ticketsBk:
      loader = () => import("./chats/Tickets/Tickets");
      break;
    case routes.cannedResponses:
      loader = () => import("./cannedResponses/CannedResponses");
      break;
    case routes.settings:
      loader = () => import("./settings/Settings");
      break;
    case routes.users:
      loader = () => import("./users/Users");
      break;
    case routes.analytics:
      loader = () => import("./analytics/Analytics");
      break;
    case routes.analyticsKpi:
      loader = () => import("./analytics/manageKpi/ManageKpi");
      break;
    case routes.liveStream:
      loader = () => import("./liveStream/LiveStream");
      break;
    case routes.campaigns:
      loader = () => import("./campaigns/Campaigns");
      break;
    case routes.campaignWhatsappTemplate:
      loader = () =>
        import("./campaigns/twilioWhatsappTemplates/WhatsappTemplates");
      break;
    case routes.campaignWhatsappTemplateCreate:
      loader = () =>
        import(
          "./campaigns/twilioWhatsappTemplates/components/CreateWhatsappTemplate"
        );
      break;
    case routes.campaignWhatsappTemplateShow:
      loader = () =>
        import(
          "./campaigns/twilioWhatsappTemplates/components/CreateWhatsappTemplate"
        );
      break;

    case routes.campaignMetaWhatsappTemplate:
      loader = () =>
        import("./campaigns/metaWhatsappTemplates/WhatsappTemplates");
      break;
    case routes.campaignMetaWhatsappTemplateCreate:
      loader = () =>
        import(
          "./campaigns/metaWhatsappTemplates/components/CreateWhatsappTemplate"
        );
      break;
    case routes.campaignMetaWhatsappTemplateShow:
      loader = () =>
        import(
          "./campaigns/metaWhatsappTemplates/components/CreateWhatsappTemplate"
        );
      break;

    case routes.pastCampaigns:
      loader = () => import("./campaigns/CampaignStats");
      break;
    case routes.pastCampaignMessages:
      loader = () => import("./campaigns/components/CampaignResults");
      break;
    case routes.contacts:
      loader = () => import("./contacts/ContactsWrapper");
      break;
    case routes.board:
      loader = () => import("./board/Board");
      break;
    case routes.boardDashboard:
      loader = () => import("./boardDashboard/BoardDashboard");
      break;
    case routes.catalog:
      loader = () => import("./catalogs/Catalogs");
      break;
    case routes.commerceBranches:
      loader = () => import("./commerce/branches/Branches");
      break;
    case routes.commerceBranchesAdd:
      loader = () => import("./commerce/branches/CreateBranch");
      break;
    case routes.commerceBranchView:
      loader = () => import("./commerce/branches/BranchView");
      break;
    case routes.commerceProducts:
      loader = () => import("./commerce/products/Products");
      break;
    case routes.commerceProfile:
      loader = () => import("./commerce/profile/Profile");
      break;
    // case routes.billing:
    //   loader = () => import("./billing/Billing");
    //   break;
    case routes.setup:
      loader = () => import("./setup/Setup");
      break;
    case routes.helpCenter:
      loader = () => import("./helpCenter/HelpCenter");
      break;
    case routes.orgKnowledge:
      loader = () => import("./orgKnowledge/OrganizationKnowledge");
      break;
    case routes.aITeams:
      loader = () => import("./aITeams/AITeams");
      break;
    case routes.workflows:
      loader = () => import("./workflows/Workflows");
      break;
    case routes.workflowEdit:
      loader = () => import("./workflows/EditWorkflow");
      break;
    case routes.emailTemplate:
      loader = () => import("./emailTemplates/EmailTemplates");
      break;
    case routes.emailTemplateCreate:
      loader = () => import("./emailTemplates/components/CreateEmailTemplate");
      break;
    case routes.emailTemplateEdit:
      loader = () => import("./emailTemplates/components/CreateEmailTemplate");
      break;
    case routes.ednaBotSettings:
      loader = () => import("./ednaBotSettings/EdnaBotSettings");
      break;
    case routes.allAnalytics:
      loader = () => import("./allAnalytics/AllAnalytics");
      break;
    case routes.calendar:
      loader = () => import("./calendar/Calendar");
      break;
    case routes.activity:
      loader = () => import("./activity/Activity");
      break;
    case routes.errorLogs:
      loader = () => import("./errorLogs/ErrorLogs");
      break;
    case routes.mfa:
      loader = () => import("./mfa/MFA");
      break;
    case routes.auth:
      loader = () => import("./auth/Auth");
      break;

    default:
      return null;
  }

  return lazy(loader);
};

export const getWebViewPage = (route: string) => {
  let loader: () => Promise<any>;

  switch (route) {
    //Edna Commerce Web View
    case webViewRoutes.webViewMenuItemModifier:
      loader = () => import("./webView/WebViewMenuItemModifier");
      break;
    case webViewRoutes.webViewConfirm:
      loader = () => import("./webView/WebViewConfirm");
      break;
    case webViewRoutes.webViewDelivery:
      loader = () => import("./webView/WebViewDeliveryInfo");
      break;
    case webViewRoutes.webViewBranch:
      loader = () => import("./webView/WebViewBranch");
      break;
    // case routes.webViewCheckOutEdnaPay:
    //   loader = () => import("./webView/WebViewCheckoutEdnaPay");
    //   break;
    case webViewRoutes.webViewOrder:
      loader = () => import("./webView/order/WebViewIntro");
      break;
    case webViewRoutes.webViewOrderMenu:
      loader = () => import("./webView/order/WebViewMenu");
      break;
    case webViewRoutes.webViewOrderCheckout:
      loader = () => import("./webView/order/WebViewCheckout");
      break;
    case webViewRoutes.webViewOrderComplete:
      loader = () => import("./webView/order/WebViewOutro");
      break;
    case webViewRoutes.webViewOrderTracking:
      loader = () => import("./webView/order/WebViewOrderTracking");
      break;
    default:
      return undefined;
  }

  return ComponentLoader(loader);
};
