import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import ReactLinkify from "react-linkify";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
// import AudioPlayer from "react-h5-audio-player";
import { TFunction, useTranslation } from "react-i18next";
import { DownloadButton, FallbackImage, ImageModal } from "../../../components";
import {
  Message as Model,
  MetaType,
  RequestStatus,
  UserType,
  ChatChannel,
  parseActivityLog,
  DeliveryStatus,
} from "../../../models";
import { useStore } from "../../../stores";
import locale from "../../../constants/locale";
import GenericSlide from "./GenericSlide";
import InstagramStory from "./InstagramStory";
import styles from "./Message.module.scss";
import HtmlRender from "./HtmlRender";
import VcfContact from "./VcfContact";
import ActionDropdown from "./ActionDropdown";
import MessageImage from "./MessageImage";
import LocationCard from "./LocationCard";
import OrderCard from "./OrderCard";
import VoiceCall from "./VoiceCall";

const getSlide = (m: any, t: TFunction<"translation", undefined>) => {
  let media: JSX.Element;
  switch (m.type) {
    case "image":
      media = <MessageImage src={m.payload?.url} meta={m.payload?.meta} />;
      break;
    case "audio":
      media = (
        <div className={m.invertColors ? "audio-inverted" : ""}>
          <audio controls src={m.payload?.url} />
          {/* <AudioPlayer
            src={m.payload?.url}
            showJumpControls={false}
            autoPlay={false}
          /> */}
          <DownloadButton
            className={m.invertColors ? "has-text-white-bis" : ""}
            src={m.payload?.url}
            name={t(locale.download)}
          />
        </div>
      );
      break;
    case "video":
      media = (
        <>
          <div className="image is-16by9 has-rounded-corners">
            <video className="has-ratio" src={m.payload?.url} controls />
          </div>
          {m.payload?.meta?.caption && <p>{m.payload.meta.caption}</p>}
        </>
      );
      break;
    case "file":
      return (
        <DownloadButton
          className={m.invertColors ? "has-text-white-bis" : ""}
          src={m.payload?.url}
          name={m.text}
        />
      );
    case "vcard":
      return <VcfContact src={m.payload?.url} invertColors={m.invertColors} />;
    case "story_mention":
      media = (
        <>
          <InstagramStory
            url={m.payload?.url}
            setImageToShow={m.setImageToShow}
          />
          <h6
            className="title is-6 mb-1 mt-1 has-text-centered"
            style={{ opacity: 0.35 }}
          >
            {t(locale.story)}
          </h6>
        </>
      );
      break;
    case "referral":
      media = (
        <>
          {!!m.payload?.url && (
            <div
              className="image is-square has-rounded-corners is-clickable"
              onClick={() =>
                m.setImageToShow && m.setImageToShow(m.payload?.url)
              }
            >
              <FallbackImage
                src={m.payload.url}
                alt="media"
                customFallback={<span>{t(locale.resourceNotFound)}</span>}
              />
            </div>
          )}
          <div className={clsx(styles.referral, "my-1", "p-2")}>
            {!!m.payload?.headline && (
              <h6 className="title is-6 mb-1 has-text-centered">
                {m.payload.headline}
              </h6>
            )}
            {!!m.payload?.body && <p className="mx-1">{m.payload.body}</p>}
            {!!m.payload?.source && (
              <div className="has-text-centered py-2 mx-1">
                <button className="button is-light is-small is-fullwidth">
                  <a href={m.payload.source} target="_blank" rel="noreferrer">
                    {t(locale.viewSource, { type: m.payload.type })}
                  </a>
                </button>
              </div>
            )}
          </div>
        </>
      );
      break;
    case "share":
      media = (
        <>
          {!!m.payload?.url && (
            <>
              <div
                className="image is-square has-rounded-corners is-clickable"
                onClick={() =>
                  m.setImageToShow && m.setImageToShow(m.payload?.url)
                }
              >
                <FallbackImage
                  src={m.payload.url}
                  alt="media"
                  customFallback={<span>{t(locale.resourceNotFound)}</span>}
                />
              </div>
              <h6
                className="title is-6 mb-1 mt-1 has-text-centered"
                style={{ opacity: 0.35 }}
              >
                Instagram {t(locale.share)}
              </h6>
            </>
          )}
        </>
      );
      break;
    case "location":
      media = <LocationCard payload={m.payload} />;
      break;
    case "order":
      media = <OrderCard payload={m.payload} />;
      break;
    case "voice_call":
      media = <VoiceCall payload={m.payload} />;
      break;
    default:
      media = <div />;
      break;
  }

  if (m.text) {
    return (
      <>
        {media}
        <ReactLinkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              href={decoratedHref}
              key={key}
              target="_blank"
              rel="noopener noreferrer"
            >
              {decoratedText}
            </a>
          )}
        >
          {m.text}
        </ReactLinkify>
      </>
    );
  }

  return media;
};

const renderCall = (
  t: TFunction<"translation", undefined>,
  data: any,
  isFromUser: boolean,
  accept: () => void,
  reject: () => void
) => {
  const callStatusMap = {
    sent: `${t(locale.calling)}…`,
    rejected: t(locale.rejected),
    timed_out: t(locale.timedOut),
    none: "",
    canceled: t(locale.canceled),
    accepted: `${t(locale.ongoing)}…`,
    completed: t(locale.callEnded),
    agent_engaged: "",
  };

  return (
    <div className="is-flex">
      <div className="avatar">
        <span
          className={clsx("icon has-text-primary", {
            "has-text-danger": ["rejected", "timed_out", "canceled"].includes(
              data.status
            ),
          })}
        >
          <i className="fas fa-video"></i>
        </span>
      </div>
      <div className="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center ml-4">
        <span>{callStatusMap[data.status as RequestStatus]}</span>
        {data.status === "completed" && (
          <span>
            {DateTime.fromISO(data.complete_time)
              .diff(DateTime.fromISO(data.response_time))
              .toFormat("mm:ss")}
          </span>
        )}
        {data.status === "sent" && isFromUser && (
          <div className="is-flex mt-2">
            <button
              type="button"
              className="button is-success mr-2"
              onClick={accept}
            >
              {t(locale.accept)}
            </button>
            <button type="button" className="button is-danger" onClick={reject}>
              {t(locale.reject)}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

// const LIVE_CHAT_EVENT_MESSAGE: Record<number, string> = {
//   [MetaType.LiveChatConnected]: locale.liveChatConnected,
//   [MetaType.LiveChatDisconnected]: locale.liveChatDisconnected,
// };

type Props = {
  onlyText?: boolean;
  message: Model;
};

const Message = ({
  onlyText,
  message: {
    id,
    text,
    senderId,
    createdAt,
    isFromUser,
    deliveryStatus,
    errorCode,
    errorMessage,
    attachment,
    attachments,
    firstName,
    lastName,
    isDeleted,
    confidential,
    metaType,
    meta,
  },
}: Props) => {
  const { t } = useTranslation();
  const { usersInConversation, selectedChat, respondToVideo } =
    useStore("chats");
  const { selectedBot } = useStore("bots");
  const { users } = useStore("users");
  const [imageToShow, setImageToShow] = useState("");
  const auth = useStore("auth");
  const date = DateTime.fromJSDate(createdAt);
  const time = date.hasSame(DateTime.utc(), "day")
    ? date.toFormat("t")
    : date.toLocaleString({
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
      });

  const user = useMemo(() => {
    if (isFromUser) {
      return {
        name: firstName
          ? `${firstName} ${lastName || ""}`
          : `${selectedChat?.firstName || ""} ${selectedChat?.lastName || ""}`,
        image: selectedChat?.image,
      };
    }

    return usersInConversation.find((f) => f.id === senderId);
  }, [
    firstName,
    isFromUser,
    lastName,
    selectedChat?.firstName,
    selectedChat?.image,
    selectedChat?.lastName,
    senderId,
    usersInConversation,
  ]);

  let fallbackUrl;

  if (isFromUser) {
    fallbackUrl = "/assets/avatar.svg";
  } else if (user) {
    fallbackUrl = "/assets/avatar.svg";
  } else {
    fallbackUrl = "/assets/bot.svg";
  }

  const image = (
    <figure className="is-flex-shrink-0">
      <p className="image is-32x32 is-square">
        <FallbackImage
          className="is-rounded"
          src={(user as any)?.avatarUrl}
          fallback={fallbackUrl}
          alt={user?.name || "user"}
        />
      </p>
    </figure>
  );

  // const isLiveChatAgentEvent =
  //   !!metaType &&
  //   [MetaType.LiveChatConnected, MetaType.LiveChatDisconnected].includes(
  //     metaType
  //   );

  // if (!text && isLiveChatAgentEvent) {
  //   return (
  //     <div
  //       className="is-flex py-3 is-relative is-justify-content-center is-align-items-center"
  //       style={{ opacity: 0.65 }}
  //     >
  //       <span>
  //         {t(LIVE_CHAT_EVENT_MESSAGE[metaType!], { agent: user?.name })}
  //       </span>
  //       <small className="ml-3">{time}</small>
  //     </div>
  //   );
  // }

  if (!text && metaType === MetaType.Transfer && meta?.to_bot_name) {
    return (
      <div
        className="is-flex py-3 is-relative is-justify-content-center is-align-items-center"
        style={{ opacity: 0.65 }}
      >
        <span>{t(locale.botTransferred, { name: meta?.to_bot_name })}</span>
        <small className="ml-3">{time}</small>
      </div>
    );
  }

  if (!text && metaType === MetaType.ActivityLog) {
    if (selectedBot?.settings?.disableChatHistory) {
      return null;
    }
    return (
      <div
        className="is-flex py-3 is-relative is-justify-content-center is-align-items-center"
        style={{ opacity: 0.65 }}
      >
        <span>{parseActivityLog(meta as any, users)}</span>
        <small className="ml-3">{time}</small>
      </div>
    );
  }

  if (!text && !attachment && !attachments && !isDeleted) {
    if (isFromUser) {
      text = "Empty Message";
      deliveryStatus = DeliveryStatus.Failed;
      errorCode = "0";
      errorMessage = "Message is empty";
    } else {
      return null;
    }
  }

  const acceptVideo = () => respondToVideo(id, "accepted");
  const rejectVideo = () => respondToVideo(id, "rejected");

  let content: React.ReactNode = (
    <ReactLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          href={decoratedHref}
          key={key}
          target="_blank"
          rel="noopener noreferrer"
        >
          {decoratedText}
        </a>
      )}
    >
      {text}
    </ReactLinkify>
  );

  if (onlyText) {
    return <>{content}</>;
  }

  if (attachment) {
    const data = attachment.payload;

    switch (attachment.type) {
      case "template":
        if (data?.template_type === "generic") {
          content = (
            <CarouselProvider
              naturalSlideWidth={280}
              naturalSlideHeight={280}
              isIntrinsicHeight
              totalSlides={data.elements.length}
            >
              <Slider>
                {data.elements.map((m: any, i: number) => (
                  <Slide index={i}>
                    <GenericSlide {...m} />
                  </Slide>
                ))}
              </Slider>
              <div className="is-flex is-justify-content-space-between mt-2">
                <ButtonBack className="button is-primary">{"<"}</ButtonBack>
                <ButtonNext className="button is-primary">{">"}</ButtonNext>
              </div>
            </CarouselProvider>
          );
        } else if (data?.template_type === "button") {
          content = <GenericSlide title={data.text} buttons={data.buttons} />;
        }
        break;
      case "video_call":
        content = renderCall(t, data, isFromUser, acceptVideo, rejectVideo);
        break;
      case "html":
        content = (
          <div className="is-overflow-y-auto">
            <HtmlRender html={data.html ?? text} />
          </div>
        );
        break;
      default:
        content = getSlide(
          {
            ...attachment,
            setImageToShow,
            text,
            invertColors: true,
          },
          t
        );
        break;
    }
  }

  if (attachments) {
    if (attachments.length === 1) {
      if (attachments[0].type === "video_call") {
        const data = attachments[0].payload;
        content = renderCall(t, data, isFromUser, acceptVideo, rejectVideo);
      } else {
        content = getSlide({ ...attachments[0], setImageToShow, text }, t);
      }
    } else {
      content = (
        <CarouselProvider
          naturalSlideWidth={280}
          naturalSlideHeight={280}
          totalSlides={attachments.length}
        >
          <Slider>
            {attachments.map((m, i) => (
              <Slide index={i}>
                {getSlide({ ...m, setImageToShow, text }, t)}
              </Slide>
            ))}
          </Slider>
          <div className="is-flex is-justify-content-space-between mt-2">
            <ButtonBack className="button is-primary">{"<"}</ButtonBack>
            <ButtonNext className="button is-primary">{">"}</ButtonNext>
          </div>
        </CarouselProvider>
      );
    }
  }

  if (isDeleted) {
    content = t(locale.messageDeleted);
  }

  let error =
    ((deliveryStatus === "failed" || deliveryStatus === "undelivered") &&
      "(" + errorCode + ") " + errorMessage) ||
    "";

  if (auth.user?.type === UserType.Collaborator && error) {
    error = "Error";
  }

  return (
    <article
      id={`chat-message-${id}`}
      className={clsx("is-flex py-3 is-relative", {
        "is-justify-content-flex-end": !isFromUser,
        "is-justify-content-flex-start": isFromUser,
      })}
    >
      {isFromUser && image}
      <div
        className={clsx(
          {
            "ml-3": isFromUser,
            "mr-3": !isFromUser,
          },
          "is-flex is-flex-direction-column"
        )}
      >
        <div
          className={clsx(
            "is-relative is-flex is-flex-direction-column is-align-items-stretch",
            styles.container,
            {
              "bubble-left": isFromUser,
              "bubble-right": !isFromUser,
              "has-background-danger": error,
              [styles.deletedMessage]: isDeleted,
              "has-text-white": isFromUser && error,
              "has-background-warning-dark": confidential,
            }
          )}
        >
          <ActionDropdown messageId={id} isFromUser={isFromUser} />
          <small
            className={clsx(
              "is-flex is-justify-content-space-between is-align-items-center",
              {
                "mb-2":
                  (!isFromUser &&
                    (user?.name || (!isFromUser ? selectedBot?.name : ""))) ||
                  ["delivered", "read"].includes(deliveryStatus as any),
              }
            )}
          >
            {!isFromUser && (
              <strong className="mr-2">
                {user?.name || (!isFromUser ? selectedBot?.name : "")}
              </strong>
            )}
            {isFromUser && selectedChat?.channel === ChatChannel.ChatRoom && (
              <strong className="mr-2">{senderId}</strong>
            )}
            <span
              className="is-flex"
              style={{
                position: "absolute",
                right: 5,
                bottom: 0,
              }}
            >
              {deliveryStatus === "delivered" && (
                <div className={clsx("ml-2", styles.messageStatus)}>
                  <i className="fas fa-check"></i>
                </div>
              )}
              {deliveryStatus === "read" && (
                <div className={clsx("ml-2", styles.messageStatus)}>
                  <i className="fas fa-check-double"></i>
                </div>
              )}
            </span>
          </small>
          <span
            className={clsx(styles.font13, "has-text-weight-medium")}
            style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
          >
            {content}
          </span>
          {!!error && (
            <small
              className={clsx(
                "is-danger is-flex is-align-items-center mt-2",
                styles.messageStatus
              )}
            >
              <span className="icon has-text-white">
                <i className="fas fa-exclamation-triangle"></i>
              </span>
              {error}
            </small>
          )}
        </div>
        <div
          className={clsx(styles.timeColor, {
            "has-text-right": !isFromUser,
          })}
        >
          {time}
        </div>
      </div>
      <div></div>
      {!isFromUser && image}
      {!!imageToShow && (
        <ImageModal
          src={imageToShow}
          isOpen
          onClose={() => setImageToShow("")}
        />
      )}
    </article>
  );
};

export default observer(Message);
