import { makeAutoObservable } from "mobx";
import {
  Board,
  BoardColumn,
  BoardItemCard,
  BoardItemComment,
  BoardItemHistory,
} from "../models";
import bots from "./bots";
import audit from "./audit";
import notifications from "./notifications";
import { NotificationType } from "../models/Notification";
import axios from "axios";
import { DateTime } from "luxon";
import { chatAdapter } from "../services/chathub";
import auth from "./auth";
import chats from "./chats";
import workflows from "./workflows";
import workspaces from "./workspaces";
import { v4 as uuid } from "uuid";

class BoardsStore {
  loading: boolean = true;
  boardMode: "ticket" | "pipeline" = "pipeline";
  selectingBoard: boolean = false;
  boards: Board[] | undefined;
  selectedBoard?: Board;
  selectedAssignee?: string;
  viewOnlyAccess: boolean = false;
  sortSelectedBoardByDesc: boolean = false;
  archivedFilter: boolean = false;
  disableSorting: boolean = false;
  userTicketsCount: Record<number, number> = {};

  constructor() {
    makeAutoObservable(this);
  }

  loadBoards = async (withColumns?: boolean) => {
    if (!bots.selectedBot) {
      return;
    }
    this.setLoading(true);
    this.setBoards(undefined);
    const {
      data: { response: boards },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          withColumns,
        },
      }
    );

    const data = boards.map(this.boardAdapter);
    this.setBoards(data);
    this.setLoading(false);
    return data;
  };

  findDealByContact = async (contactId: number) => {
    const {
      data: { response: data },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/item/${contactId}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );

    return this.boardItemCardAdapter(data);
  };

  findDealByUuid = async (uuid: string) => {
    const {
      data: { response: data },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${uuid}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );

    return this.boardItemCardAdapter(data);
  };

  findBoardByLivecardId = async (
    groudId: string,
    filters?: Record<string, any>,
    withoutItems?: boolean
  ) => {
    if (!bots.selectedBot || !groudId) {
      return;
    }

    this.setSelectingBoard(true);
    this.selectedBoard = undefined;
    this.disableSorting =
      Object.values(filters ?? {}).filter((f) => f).length > 0;

    const {
      data: { response: data },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/livecard/${groudId}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          without_items: withoutItems,
          ...filters,
        },
      }
    );

    const board = this.boardAdapter(data);
    board.columns = data.columns.map((m: any) => {
      m.items = m.items.map(this.boardItemCardAdapter);
      return {
        ...this.boardColumnAdapter(m),
        items: m.items,
      };
    });

    this.selectedBoard = board;

    this.setSelectingBoard(false);
  };

  selectBoard = async (uuid?: string, filters?: Record<string, any>) => {
    if (!bots.selectedBot || !uuid) {
      return;
    }

    this.setSelectingBoard(true);
    this.selectedBoard = undefined;
    this.disableSorting =
      Object.values(filters ?? {}).filter((f) => f).length > 0;

    const {
      data: { response: data },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${uuid}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          ...filters,
        },
      }
    );

    const board = this.boardAdapter(data);
    board.columns = data.columns.map((m: any) => {
      m.items = m.items.map(this.boardItemCardAdapter);
      return {
        ...this.boardColumnAdapter(m),
        items: m.items,
      };
    });

    this.selectedBoard = board;

    this.setSelectingBoard(false);
  };

  createBoard = async (
    board: Omit<Board, "id" | "uuid" | "updatedAt" | "createdAt">
  ): Promise<void> => {
    if (!bots.selectedBot) {
      return;
    }

    const {
      data: { response: data },
    } = await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        name: board.name,
      }
    );

    audit.logEvent("board_created", {
      id: data.id,
      doc: data,
      botId: bots.selectedBot.id,
    });

    const boardData = this.boardAdapter(data);
    this.setBoards([boardData, ...(this.boards || [])]);

    await this.selectBoard(data.uuid);
  };

  updateBoard = async (board: any): Promise<void> => {
    if (!bots.selectedBot) {
      return;
    }

    try {
      const {
        data: { response: data },
      } = await axios.put(
        `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}`,
        {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          name: board.name,
          is_default: board.isDefault,
        }
      );

      const boardData = this.boardAdapter(data);

      this.setBoards(
        this.boards?.map((m) => {
          if (m.id === data.id) {
            return boardData;
          }
          if (board?.isDefault) {
            m.isDefault = false;
          }
          return m;
        })
      );

      audit.logEvent("board_updated", {
        id: board.id,
        doc: board,
        botId: bots.selectedBot.id,
      });
    } catch (error) {
      throw error;
    }
  };

  deleteBoard = async (id: string): Promise<void> => {
    if (!bots.selectedBot) {
      return;
    }

    await axios.delete(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${id}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );

    this.setBoards(this.boards?.filter((f) => f.uuid !== id));

    this.selectedBoard = undefined;

    audit.logEvent("board_deleted", {
      id: id,
      botId: bots.selectedBot.id,
    });
  };

  loadMoreItems = async (
    columnId: string,
    skip?: number,
    filters?: Record<string, any>
  ) => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }
    const {
      data: { response: data },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/load-more`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          column: columnId,
          skip: skip,
          limit: 50,
          ...filters,
        },
      }
    );

    const items = data.map(this.boardItemCardAdapter);

    this.selectedBoard = {
      ...this.selectedBoard,
      columns: this.selectedBoard?.columns?.map((m) => {
        if (m.uuid === columnId) {
          return {
            ...m,
            items: [...(m.items || []), ...items],
          };
        }
        return m;
      }),
    };
  };

  createColumn = async (
    column: Omit<BoardColumn, "id" | "updatedAt" | "createdAt">
  ): Promise<void> => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    const {
      data: { response: data },
    } = await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/column`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        name: column.name,
        type: column.type,
        probability: column.probability ?? 0,
        forecast_category: column.forecastCategory ?? "closed",
        column_sorting: column.columnSorting,
        description: column.description,
      }
    );

    const columnData = this.boardColumnAdapter(data);
    this.selectedBoard = {
      ...this.selectedBoard,
      columns: [...(this.selectedBoard?.columns || []), columnData],
    };
  };

  updateColumn = async (column: BoardColumn): Promise<void> => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/column/${column.uuid}`,
        {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
          name: column.name,
          type: column.type,
          probability: column.probability ?? 0,
          forecast_category: column.forecastCategory ?? "closed",
          column_sorting: column.columnSorting,
          description: column.description,
        }
      );

      await this.selectBoard(this.selectedBoard.uuid);
    } catch (error) {
      throw error;
    }
  };

  deleteColumn = async (id: string): Promise<void> => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    await axios.delete(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/column/${id}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );

    this.selectedBoard = {
      ...this.selectedBoard,
      columns: this.selectedBoard?.columns?.filter((f) => f.uuid !== id),
    };
  };

  addItemToBoard = async (item: BoardItemCard, columnId: number) => {
    if (!this.selectedBoard || !this.selectedBoard) {
      return;
    }

    const currentColumn = this.selectedBoard.columns?.find(
      (f) => f.id === columnId
    );

    if (!currentColumn) {
      return;
    }

    const {
      data: { response },
    } = await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        user_id: auth.user?.id,
        columnId: currentColumn.uuid,
        subscriber_id: item.subscriberId,
        name: item.name,
        opportunity: item.opportunity || 0,
        assignee_id: item.assigneeId,
        due_date: item.dueDate,
        type: item.type,
        priority: item.priority,
        status: item.status,
        livecard_id: item.livecardId,
        attachments: item.attachments,
        labels: item.labels,
        before_item_id: 0,
        custom_fields: item.customFields,
        closed_lost_reason: item.closedLostReason,
        repeat_frequency: item.repeatFrequency,
        repeat_config: item.repeatConfig,
      }
    );
    const itemData = this.boardItemCardAdapter(response.item);
    const columnData = this.boardColumnAdapter(response.column);

    this.selectedBoard = {
      ...this.selectedBoard,
      columns: this.selectedBoard?.columns?.map((m) =>
        m.uuid === currentColumn.uuid
          ? {
              ...m,
              noOfItems: columnData.noOfItems,
              sumOfOpportunity: columnData.sumOfOpportunity,
              items: [...(m.items || []), itemData],
            }
          : m
      ),
    };

    if (itemData.subscriberId) {
      await chats.saveContactData(itemData.subscriberId, {
        dealBoardId: this.selectedBoard.id,
        assignee: itemData.assigneeId as any,
        dealId: itemData.id,
        dealStageType: currentColumn.type,
      });
    }

    if (item?.comments?.length) {
      for (const comment of item.comments) {
        await this.createComment(itemData, comment);
      }
    }

    return itemData;
  };

  updateItemToBoard = async (
    item: BoardItemCard,
    columnId: number,
    oldColumn: number,
    currentAssignee?: string
  ) => {
    if (!this.selectedBoard || !this.selectedBoard) {
      return;
    }

    const column = this.selectedBoard.columns?.find((f) => f.id === columnId);

    if (!column) {
      return;
    }
    const currentItemId = column.items?.findIndex((f) => f.uuid === item.uuid);

    const {
      data: { response },
    } = await axios.put(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${item.uuid}`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        user_id: auth.user?.id,
        columnId: column.uuid,
        subscriber_id: item.subscriberId,
        name: item.name,
        opportunity: item.opportunity || 0,
        assignee_id: item.assigneeId,
        due_date: item.dueDate,
        type: item.type,
        priority: item.priority,
        status: item.status,
        livecard_id: item.livecardId,
        attachments: item.attachments,
        labels: item.labels,
        index: currentItemId,
        custom_fields: item.customFields,
        closed_lost_reason: item.closedLostReason,
        repeat_frequency: item.repeatFrequency,
        repeat_config: item.repeatConfig,
      }
    );
    const itemData = this.boardItemCardAdapter(response.item);
    const columnsData: BoardColumn[] = response.columns.map(
      this.boardColumnAdapter
    );

    this.selectedBoard = {
      ...this.selectedBoard,
      columns: this.selectedBoard?.columns?.map((m) => {
        const column = columnsData.find((f) => f.id === m.id) ?? m;
        return {
          ...m,
          sumOfOpportunity: column?.sumOfOpportunity,
          noOfItems: column?.noOfItems,
          items:
            m.items?.map((l) => (l.uuid === item.uuid ? itemData : l)) ?? [],
        };
      }),
    };

    if (itemData.subscriberId) {
      // TODO clear old chat assignee
      await chats.saveContactData(itemData.subscriberId, {
        assignee: item.assigneeId as any,
        dealBoardId: this.selectedBoard.id,
        dealId: item.id,
        dealStageType: column?.type,
      });
    }

    if (this.boardMode === "pipeline") {
      if (columnId !== oldColumn) {
        await workflows.triggerWorkflowEvent("automation_pipeline", {
          chatId: itemData.subscriberId,
          boardId: this.selectedBoard.id,
          fromColumnId: oldColumn,
          toColumnId: columnId,
        });
      }

      if (item.assigneeId !== currentAssignee) {
        await workflows.triggerWorkflowEvent("automation_pipeline", {
          chatId: itemData.subscriberId,
          boardId: this.selectedBoard.id,
          triggered_event: "assignee_changed",
        });
      }
    }

    return itemData;
  };

  archivedItem = async (item: BoardItemCard) => {
    if (!this.selectedBoard || !this.selectedBoard) {
      return;
    }

    await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${item.uuid}/archived`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
      }
    );
    await this.selectBoard(this.selectedBoard.uuid, {
      assignee: this.selectedAssignee,
      orderByNewDeals: this.sortSelectedBoardByDesc,
      archived: this.archivedFilter,
    });
  };

  notifyMentionedUsers = async (
    item: BoardItemCard,
    comment: BoardItemComment,
    type: NotificationType
  ) => {
    if (!this.selectedBoard) {
      return;
    }

    const userIds =
      comment.comment
        ?.match(/(@\[.*?\]\([^)]+\))/g)
        ?.map((f) => f.replace(/(@\[.*?\]\(|\))/g, "")) || [];

    const mentionedUsers = Array.from(new Set(userIds)).filter(
      (f) => !comment?.meta?.mentionedUsers?.includes(f)
    );

    for (const userId of mentionedUsers) {
      await notifications.createNotification(userId, type, {
        boardId: this.selectedBoard.uuid,
        item: JSON.parse(JSON.stringify(item)),
        comment,
      });
    }

    return [...(comment.meta?.mentionedUsers || []), ...mentionedUsers];
  };

  deleteItemFromBoard = async (item: BoardItemCard, columnId: number) => {
    if (!this.selectedBoard) {
      return;
    }
    await axios.delete(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${item.uuid}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );

    this.selectedBoard = {
      ...this.selectedBoard,
      columns: this.selectedBoard?.columns?.map((m) => {
        if (m.id === columnId) {
          return {
            ...m,
            noOfItems: m.noOfItems - 1,
            sumOfOpportunity: m.sumOfOpportunity - item.opportunity,
            items: m.items?.filter((f) => f.uuid !== item.uuid),
          };
        }
        return m;
      }),
    };

    if (item.subscriberId) {
      await chats.saveContactData(item.subscriberId, {
        dealBoardId: "",
        assignee: "",
        dealId: "",
        dealStageType: "",
      });
    }
  };

  loadComments = async (itemId: string) => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return [];
    }

    const {
      data: { response: data },
    } = await axios.get(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${itemId}/comment`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );

    return data.map(this.boardItemCommentAdapter);
  };

  createComment = async (item: BoardItemCard, comment: BoardItemComment) => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    comment.meta = {
      refId: uuid(),
    };

    comment.meta.mentionedUsers = await this.notifyMentionedUsers(
      item,
      JSON.parse(JSON.stringify(comment)),
      this.boardMode === "ticket"
        ? NotificationType.TICKET_MENTION
        : NotificationType.DEAL_MENTION
    );

    const {
      data: { response: data },
    } = await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${item.uuid}/comment`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        user_id: comment.userId,
        comment: comment.comment,
        meta: comment.meta,
      }
    );

    return this.boardItemCommentAdapter(data);
  };

  updateComment = async (
    item: BoardItemCard,
    commentId: number,
    comment: any
  ) => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    const mentionedUsers = await this.notifyMentionedUsers(
      item,
      comment,
      this.boardMode === "ticket"
        ? NotificationType.TICKET_MENTION
        : NotificationType.DEAL_MENTION
    );

    comment.meta = {
      ...comment.meta,
      mentionedUsers,
    };

    const {
      data: { response: data },
    } = await axios.put(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${item.uuid}/comment/${commentId}`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        comment: comment.comment,
        user_id: comment.userId,
        meta: comment.meta,
      }
    );

    return this.boardItemCommentAdapter(data);
  };

  deleteComment = async (itemId: string, commentId: number) => {
    if (!bots.selectedBot || !this.selectedBoard) {
      return;
    }

    await axios.delete(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${this.selectedBoard?.uuid}/item/${itemId}/comment/${commentId}`,
      {
        params: {
          key: bots.selectedBot?.key,
          password: bots.selectedBot?.password,
        },
      }
    );
  };

  setAssignee = (assignee: string) => {
    this.selectedAssignee = assignee;
  };

  setViewOnlyAccess = (viewOnlyAccess: boolean) => {
    this.viewOnlyAccess = viewOnlyAccess;
  };

  private setBoards = (boards?: Board[]) => {
    this.boards = boards?.filter((f) => !f.deletedAt);
  };

  setSelectedBoard = (board?: Board) => {
    this.selectedBoard = board;
  };

  private setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  private setSelectingBoard = (loading: boolean) => {
    this.selectingBoard = loading;
  };

  setBoardMode = (mode: "ticket" | "pipeline") => {
    this.boardMode = mode;
  };

  // private createDefaultBoard = async () => {
  //   if (!bots.selectedBot) {
  //     return;
  //   }

  //   const board = await this.createBoard(defaultBoard as any);

  //   if (board) {
  //     this.selectBoard(board);
  //   }
  // };

  setSortSelectedBoardByDesc = (bool: boolean) => {
    this.sortSelectedBoardByDesc = bool;
  };

  setArchived = (bool: boolean) => {
    this.archivedFilter = bool;
  };

  boardAdapter = (item: any): Board => {
    return {
      id: item.id,
      uuid: item.uuid,
      name: item.name,
      columns: item.columns?.map(this.boardColumnAdapter),
      isDefault: item.is_default,
      createdAt: DateTime.fromISO(item.created_at, {
        zone: "utc",
      }).toLocal(),
      updatedAt: DateTime.fromISO(item.updated_at, {
        zone: "utc",
      }).toLocal(),
    };
  };

  boardColumnAdapter = (item: any): BoardColumn => {
    return {
      id: item.id,
      uuid: item.uuid,
      name: item.name,
      columnSorting: item.column_sorting,
      type: item.type,
      probability: item.probability,
      forecastCategory: item.forecast_category,
      noOfItems: item.no_of_items,
      sumOfOpportunity: item.sum_of_opportunity,
      description: item.description,
      createdAt: DateTime.fromISO(item.created_at, {
        zone: "utc",
      }).toLocal(),
      updatedAt: DateTime.fromISO(item.updated_at, {
        zone: "utc",
      }).toLocal(),
    };
  };

  callAnalyticsHandlder = async (
    boardId: string,
    name: string,
    params?: Record<string, any>
  ) => {
    if (!bots.selectedBot) {
      return;
    }

    const {
      data: { response },
    } = await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/${boardId}/analytics`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        name,
        ...params,
      }
    );

    return response;
  };

  loadTicketsCount = async () => {
    if (!bots.selectedBot || !auth.user?.id) return;

    const livecardIds = workspaces.activeLiveChatCards?.map((f) => f.id);

    const {
      data: { response: data },
    } = await axios.post(
      `${process.env.REACT_APP_CHATHUB_APP_URL}/api/${bots.selectedBot?.id}/board/tickets-count`,
      {
        key: bots.selectedBot?.key,
        password: bots.selectedBot?.password,
        livecard_ids: livecardIds,
        user_id: auth.user?.id,
      }
    );
    this.userTicketsCount = data;
  };

  boardItemCardAdapter = (item: any): BoardItemCard => {
    return {
      id: item.id,
      uuid: item.uuid,
      name: item.name,
      boardId: item.board_id,
      columnId: item.board_column_id,
      subscriberId: item.subscriber_id,
      dueDate:
        item.due_date &&
        DateTime.fromISO(item.due_date, {
          zone: "utc",
        }).toLocal(),
      assigneeId: item.assignee_id,
      opportunity: item.opportunity,
      createdAt: DateTime.fromISO(item.created_at, {
        zone: "utc",
      }).toLocal(),
      updatedAt: DateTime.fromISO(item.updated_at, {
        zone: "utc",
      }).toLocal(),
      openedAt:
        item.opened_at &&
        DateTime.fromISO(item.opened_at, {
          zone: "utc",
        }).toLocal(),
      closedAt:
        item.closed_at &&
        DateTime.fromISO(item.closed_at, {
          zone: "utc",
        }).toLocal(),
      archivedAt:
        item.archived_at &&
        DateTime.fromISO(item.archived_at, {
          zone: "utc",
        }).toLocal(),
      closedLostReason: item.closed_lost_reason,
      priority: item.priority,
      status: item.status,
      livecardId: item.livecard_id,
      type: item.type,
      comments: item.comments,
      customFields: item.custom_fields,
      labels: item.labels,
      attachments: item.attachments,
      repeatFrequency: item.repeat_frequency,
      repeatConfig: item.repeat_config,
      meta: item.meta,
      subscriber: item.subscriber && chatAdapter(item.subscriber),
      histories: item.histories?.map(
        (m: any): BoardItemHistory => ({
          createdBy: m.created_by,
          currentStageId: m.current_column_id,
          newStageId: m.new_column_id,
          addedAt: DateTime.fromISO(m.created_at, {
            zone: "utc",
          }).toLocal(),
          removedAt:
            m.removed_at &&
            DateTime.fromISO(m.removed_at, {
              zone: "utc",
            }).toLocal(),
        })
      ),
    };
  };

  boardItemCommentAdapter = (item: any): BoardItemComment => {
    return {
      id: item.id,
      userId: item.user_id,
      comment: item.comment,
      meta: item.meta,
      createdAt: DateTime.fromISO(item.created_at, {
        zone: "utc",
      }).toLocal(),
      updatedAt: DateTime.fromISO(item.updated_at, {
        zone: "utc",
      }).toLocal(),
    };
  };
}

export default new BoardsStore();
