import firebaseApp from "firebase/compat/app";
import {
  TicketMentionParser,
  MentionParser,
  ThreadParser,
  DealMentionParser,
} from "../components/notification/components/parsers";
import { EdnaChat, EdnaMessage } from "./EdnaChat";
import { BoardItemCard, BoardItemComment } from "./Board";
import locale from "../constants/locale";
import { t } from "i18next";

export enum NotificationType {
  DEAL_MENTION = "deal_mention",
  TICKET_MENTION = "ticket_mention",
  MENTION = "mention",
  THREAD = "thread",
}

export const notificationTypeParser: Record<NotificationType, any> = {
  [NotificationType.DEAL_MENTION]: DealMentionParser,
  [NotificationType.TICKET_MENTION]: TicketMentionParser,
  [NotificationType.MENTION]: MentionParser,
  [NotificationType.THREAD]: ThreadParser,
};

//userName is mentioned you in (chatname|their chat)
export type MentionNotificationMeta = {
  chatName: string;
  chat: EdnaChat;
  message: EdnaMessage;
};

//userName replied to your message in (chatname|their chat)
export type ThreadNotificationMeta = {
  chatName: string;
  parentChat: EdnaChat; //parent chat
  chat: EdnaChat;
  message: EdnaMessage;
};

//userName is mentioned you in ticket (ticketName)
export type TicketMentionNotificationMeta = {
  item: BoardItemCard;
  comment: BoardItemComment;
};

//userName is mentioned you in deal (dealName)
export type DealMentionNotificationMeta = {
  boardId: string;
  item: BoardItemCard;
  comment: BoardItemComment;
};

export type Notification = {
  id?: string;
  receiverId: string;
  senderId: string;
  type: NotificationType;
  readAt?: firebaseApp.firestore.FieldValue;
  createdAt: firebaseApp.firestore.FieldValue;
  updatedAt: firebaseApp.firestore.FieldValue;
} & (
  | {
      type: NotificationType.MENTION;
      meta: MentionNotificationMeta;
    }
  | {
      type: NotificationType.THREAD;
      meta: ThreadNotificationMeta;
    }
  | {
      type: NotificationType.TICKET_MENTION;
      meta: TicketMentionNotificationMeta;
    }
  | {
      type: NotificationType.DEAL_MENTION;
      meta: DealMentionNotificationMeta;
    }
);

export const parseNotificationText = (notification: Notification) => {
  const types: Record<NotificationType, (meta: any) => string> = {
    [NotificationType.DEAL_MENTION]: (meta: DealMentionNotificationMeta) => {
      return t(locale.hasMentionedYouIn, {
        chatName: "deal " + t(meta.item?.name ?? ""),
      });
    },
    [NotificationType.TICKET_MENTION]: (
      meta: TicketMentionNotificationMeta
    ) => {
      return t(locale.hasMentionedYouIn, {
        chatName: "ticket " + t(meta.item?.name ?? ""),
      });
    },
    [NotificationType.MENTION]: (meta: MentionNotificationMeta) => {
      return t(locale.hasMentionedYouIn, {
        chatName: t(meta.chatName),
      });
    },
    [NotificationType.THREAD]: (meta: ThreadNotificationMeta) => {
      return t(locale.repliedInAThread, {
        chatName: t(meta.chatName),
      });
    },
  };

  return types[notification.type](notification.meta);
};
