import React from "react";
import { CiShoppingCart } from "react-icons/ci";

interface ProductItem {
  name?: string;
  product_retailer_id: string;
  quantity: number;
  item_price: number;
  currency: string;
}

interface OrderPayload {
  id?: string;
  db_order?: any;
  catalog_id: string;
  text: string | null;
  product_items: ProductItem[];
}

interface WhatsAppOrderProps {
  payload: OrderPayload;
}

const OrderCard: React.FC<WhatsAppOrderProps> = ({ payload }) => {
  const formatCurrency = (amount: number, currency: string): string => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).format(amount);
  };

  const calculateTotal = (items: ProductItem[]): number => {
    return items.reduce(
      (total, item) => total + item.quantity * item.item_price,
      0
    );
  };

  return (
    <div className="mt-2">
      <header className="">
        <p className="card-header-title p-0">
          <span className="icon-text">
            <span className="icon">
              <CiShoppingCart size={20} />
            </span>
            <span>
              {payload.id ? (
                <>OrderID #{payload.id}</>
              ) : (
                <>Order CatalogId #{payload.catalog_id}</>
              )}
            </span>
          </span>
        </p>
      </header>

      <div className="card-content p-0 mt-2">
        <div className="content">
          {payload.product_items.map((item, index) => (
            <div key={index} className="box mb-3 has-background-light">
              <div className="columns is-mobile is-vcentered">
                <div className="column">
                  <p className="is-size-6 has-text-weight-medium">
                    Product ID: {item?.name ?? item.product_retailer_id}
                  </p>
                  <p className="is-size-7 has-text-grey">
                    Quantity: {item.quantity}
                  </p>
                </div>
                <div className="column is-narrow has-text-right">
                  <p className="has-text-weight-bold">
                    {formatCurrency(item.item_price, item.currency)}
                  </p>
                  <p className="is-size-7 has-text-grey">{item.currency}</p>
                </div>
              </div>
            </div>
          ))}

          <div
            className="mt-4 pt-4 is-flex is-justify-content-space-between"
            style={{ borderTop: "1px solid #dbdbdb" }}
          >
            <span className="has-text-weight-bold">Total Amount:</span>
            <span className="has-text-weight-bold">
              {formatCurrency(
                calculateTotal(payload.product_items),
                payload.product_items[0]?.currency || "PKR"
              )}
            </span>
          </div>

          {payload.text && (
            <div className="mt-4 is-size-7 has-text-grey">
              Note: {payload.text}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
