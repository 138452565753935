import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";
import { FirebaseSubscriber } from "./utils";
import { logError } from "../services/logging";
import { RealTimeEvent } from "../models";
import bots from "./bots";
import chats from "./chats";

class RealTimeEventsStore {
  private db: firebase.firestore.Firestore;
  private subscriber?: FirebaseSubscriber;
  private initialLoaded = false;

  constructor() {
    makeAutoObservable(this);
    this.db = firebase.firestore();
  }

  subscribed = async () => {
    if (!bots.selectedBot) return;

    if (this.subscriber?.id === bots.selectedBot.id) {
      return;
    }

    if (this.subscriber) {
      this.subscriber.unsubscribe();
    }

    this.subscriber = {
      id: bots.selectedBot.id,
      unsubscribe: this.db
        .collection("bots")
        .doc(String(bots.selectedBot.id))
        .collection("realTimeEvents")
        .orderBy("createdAt", "desc")
        .onSnapshot(
          (
            snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
          ) => {
            snap.docChanges().forEach((change) => {
              const data = change.doc.data() as RealTimeEvent;
              data.id = change.doc.id;
              if (change.type === "added") {
                this.handleEvents(data);
              }
            });

            if (!this.initialLoaded) {
              this.initialLoaded = true;
            }
          },
          logError
        ),
    };
  };

  handleEvents = (event: RealTimeEvent) => {
    // console.log("event", event.name, event);

    if (event.name === "chat.updated") {
      chats.handleUpdateChatEvent(event);
      chats.handleRealTimeChatCount(event);
    } else if (event.name === "chat.agent.connected") {
      chats.handleAgentConnectedEvent(event);
    } else if (event.name === "message.new") {
      chats.handleNewMessageEvent(event);
    } else if (event.name === "message.updated") {
      chats.handleUpdateMessageEvent(event);
    } else if (/^live_chat\./.test(event.name)) {
      chats.handleLiveChatRequest(event);
      chats.handleRealTimeChatCount(event);
    } else if (event.name === "chat.assigned") {
      chats.handleChatAssignedEvent(event);
    } else if (event.name === "chat.assigned_no_response") {
      chats.handleChatAssignedEvent(event, false);
    } else if (event.name === "chat.transferred") {
      chats.handleChatTransferredEvent(event);
    } else if (event.name === "snoozed-chat.move-to-my-queue") {
      chats.handleSnoozedChatMoveToMyQueue(event);
    }
  };
}

export default new RealTimeEventsStore();
