import clsx from "clsx";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover } from "react-tiny-popover";
import locale from "../../../constants/locale";
import { UserPermissions, userStatusColorMap } from "../../../models";
import AvatarModal from "../../../pages/auth/AvatarModal";
import UpdateUserStatus from "../../../pages/auth/UpdateUserStatus";
import styles from "../../../pages/chats/components/ChatsList.module.scss";
import { useStore } from "../../../stores";
import { observer } from "mobx-react-lite";
import { resetTour } from "../../../utils/tour";
import { FallbackImage } from "../../fallbackImage/FallbackImage";
import firebase from "../../../services/firebase";
import ItemModal from "../../../pages/board/components/ItemModal";
import { FullScreenLoader } from "../..";

const ProfilePopover = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isUpdateStatusOpen, setIsUpdateStatusOpen] = useState(false);
  const [isAvatarModal, setIsAvatarModal] = useState(false);
  const { selectedWorkspace } = useStore("workspaces");
  const [contentKey, setContentKey] = useState(0);
  const [notificationEnabled, setNotificationEnabled] = useState(
    !!localStorage.getItem("fcmToken")
  );
  const [openTicketModal, setOpenTicketModal] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(false);

  const { t } = useTranslation();
  const {
    user,
    logOut,
    updateCurrentUser,
    remove2Fa,
    updatePermissions,
    setFCMToken,
    deleteFCMToken,
  } = useStore("auth");
  const { setBoardMode, findBoardByLivecardId } = useStore("boards");

  const mfaEnabled = useMemo(() => user?.enrolled2fa, [user]);

  const updateUserPermissions = useCallback(
    async (permissions: any) => {
      if (!selectedWorkspace) {
        return;
      }

      const data = {
        ...user?.permissions,
        perWorkspace: {
          ...user?.permissions?.perWorkspace,
          [selectedWorkspace.id]: {
            ...user?.permissions?.perWorkspace?.[selectedWorkspace.id],
            ...permissions,
            perFunction:
              user?.permissions?.perWorkspace?.[selectedWorkspace.id]
                ?.perFunction,
          },
        },
      };

      await updatePermissions(data);
    },
    [user, selectedWorkspace, updatePermissions]
  );

  const permissions = useMemo(
    () => user?.permissions?.perWorkspace?.[selectedWorkspace?.id || ""],
    [selectedWorkspace?.id, user?.permissions?.perWorkspace]
  ) as UserPermissions["perWorkspace"];

  const loadTicketConfig = useCallback(async () => {
    if (!selectedWorkspace?.helpCenterSettings?.ticketLivecardId) {
      return;
    }
    setLoadingTicket(true);
    setBoardMode("ticket");
    try {
      await findBoardByLivecardId(
        String(selectedWorkspace?.helpCenterSettings?.ticketLivecardId)
      );
      setLoadingTicket(false);
      setOpenTicketModal(true);
    } catch (error) {}
  }, [
    setBoardMode,
    findBoardByLivecardId,
    selectedWorkspace?.helpCenterSettings?.ticketLivecardId,
  ]);

  const meuns = useMemo(
    () => [
      {
        name: (
          <div className="is-flex is-align-items-center">
            {user?.status && (
              <div
                className={clsx("mr-1")}
                style={{
                  borderRadius: "50%",
                  background: userStatusColorMap[user.status],
                  height: "1rem",
                  width: "1rem",
                }}
              />
            )}
            <span>{t(locale.status)}</span>
          </div>
        ),
        onClick: () => {
          setIsPopoverOpen(false);
          setIsUpdateStatusOpen(true);
        },
      },
      {
        name:
          t(permissions?.disableProductTour ? locale.enable : locale.disable) +
          " " +
          t(locale.tour),
        onClick: () => {
          updateUserPermissions({
            ...permissions,
            disableProductTour: !permissions?.disableProductTour,
          });
          if (permissions?.disableProductTour) {
            resetTour();
          }
        },
      },
      {
        name: t(locale.affiliate),
        onClick: () => {
          const data = {
            id: user?.id,
            email: user?.email,
            name: user?.name,
          };
          window.open(
            `${process.env.REACT_APP_EDNA_AFFILIATE_URL}/sso?data=${btoa(
              JSON.stringify(data)
            )}`,
            "_blank"
          );
        },
      },
      {
        name: !notificationEnabled
          ? "Enable Push Notification"
          : "Disable Push Notification",
        onClick: async () => {
          try {
            if (notificationEnabled) {
              setNotificationEnabled(false);
              await deleteFCMToken(localStorage.getItem("fcmToken")!);
            } else {
              const permission = await Notification.requestPermission();
              if (permission === "granted") {
                console.log("Notification permission granted.");
                const token = await firebase.messaging().getToken({
                  vapidKey: process.env.REACT_APP_VAPID_KEY,
                });
                console.log("FCM Token:", token);
                await setFCMToken(token);
                setNotificationEnabled(true);
              } else {
                console.log("Notification permission denied.");
              }
            }
            setContentKey((p) => p++);
            setIsPopoverOpen(false);
          } catch (error) {
            console.error("Error getting permission or token", error);
          }
        },
      },
      {
        name: mfaEnabled ? "Disable 2FA" : "Enable 2FA",
        onClick: async () => {
          if (!user) return;
          try {
            if (mfaEnabled) {
              await updateCurrentUser({
                ...user,
                skip2fa: true,
                enrolled2fa: false,
                phoneNumber: "",
              });
              remove2Fa();
            } else {
              await updateCurrentUser({
                ...user,
                skip2fa: false,
                enrolled2fa: false,
                phoneNumber: "",
              });
              window.location.href = "/verify";
            }
            setContentKey((p) => p++);
            setIsPopoverOpen(false);
          } catch (error) {
            console.error("Error setting 2fa", error);
          }
        },
      },
      ...(selectedWorkspace?.helpCenterSettings?.ticketLivecardId
        ? [
            {
              name: t(locale.submitATicket),
              onClick: loadTicketConfig,
            },
          ]
        : []),
      {
        name: t(locale.logout),
        onClick: logOut,
      },
    ],
    [
      user,
      t,
      permissions,
      notificationEnabled,
      mfaEnabled,
      selectedWorkspace?.helpCenterSettings?.ticketLivecardId,
      loadTicketConfig,
      logOut,
      updateUserPermissions,
      deleteFCMToken,
      setFCMToken,
      updateCurrentUser,
      remove2Fa,
    ]
  );

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={["bottom", "right"]}
        padding={7}
        containerStyle={{ zIndex: "10000" }}
        onClickOutside={() => setIsPopoverOpen(false)}
        content={() => (
          <>
            <div
              key={contentKey}
              className={clsx(styles.channelWrapper, "is-clickable")}
            >
              {meuns.map((menu, i) => {
                return (
                  <div
                    key={i}
                    onClick={menu.onClick}
                    className={clsx(styles.channelName)}
                  >
                    {menu.name}
                  </div>
                );
              })}
            </div>
          </>
        )}
      >
        <div className={clsx("is-flex is-align-items-center is-clickable")}>
          <figure className="is-flex-shrink-0 mr-1">
            <p className="image is-32x32 is-square">
              <FallbackImage
                className="is-rounded"
                src={user?.avatarUrl || "/assets/avatar.svg"}
                fallback="/assets/avatar.svg"
                alt={user?.name}
                onClick={() => setIsAvatarModal(true)}
              />
              {user?.status && (
                <div
                  className={clsx("ml-1 ", styles.status)}
                  style={{
                    background: userStatusColorMap[user.status as never],
                  }}
                />
              )}
            </p>
          </figure>
          <span
            className="icon"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <img src="/assets/chevron-down.svg" alt="chevron-down" />
          </span>
        </div>
      </Popover>

      <AvatarModal
        open={isAvatarModal}
        onClose={() => setIsAvatarModal(false)}
      />

      <UpdateUserStatus
        open={isUpdateStatusOpen}
        onClose={() => setIsUpdateStatusOpen(false)}
      />

      {openTicketModal && (
        <ItemModal
          defaultOpen={openTicketModal}
          onClose={() => setOpenTicketModal(false)}
          isHelpCenter
          item={
            {
              livecardId:
                selectedWorkspace?.helpCenterSettings?.ticketLivecardId,
            } as any
          }
        />
      )}

      {loadingTicket && <FullScreenLoader isOpen />}
    </>
  );
};

export default observer(ProfilePopover);
